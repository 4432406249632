import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import AttachFileSharpIcon from '@mui/icons-material/AttachFileSharp';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  download: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function KenUploadButton(props) {
  const classes = useStyles();
  const { open, recordId, handleDownload, handleDelete, fileType, fileName } =
    props;

  return (
    <React.Fragment>
      {!recordId ? (
        <Grid container spacing={2} justifyContent={'space-evenly'}>
          <Grid item xs={props?.templateDownload === true ? 7 : 12}>
            <Button
              variant="secondary"
              onClick={open}
              startIcon={<AttachFileSharpIcon style={{ color: '#092682' }} />}
              style={{
                color: '#092682',
                border: '1px dashed #092682',
                borderRadius: 6,
                width: '100%',
              }}
            >
              Choose or Drag file
            </Button>
          </Grid>
          {props?.templateDownload === true && (
            <Grid item xs={5}>
              <Button
                variant="secondary"
                onClick={() => window.open(props?.templateURL)}
                startIcon={<FileDownloadIcon style={{ color: '#000000' }} />}
                style={{
                  color: '#092682',
                  border: '1px dashed #092682',
                  borderRadius: 6,
                  width: '100%',
                }}
              >
                Template
              </Button>
            </Grid>
          )}
        </Grid>
      ) : (
        <Box>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent={'center'}
            alignSelf={'center'}
          >
            <Grid item md={3} sx={{ textAlign: 'center' }}>
              <Typography
                style={{
                  color: '#ffffff',
                  fontSize: 14,
                  opacity: '0.6',
                  borderRadius: 6,
                  textAlign: 'center',
                  width: '100%',
                  background: '#008000',
                  textTransform: 'uppercase',
                  padding: '12px 0px',
                }}
              >
                Submitted
              </Typography>
            </Grid>
            <Grid item md={1} sx={{ textAlign: 'center' }}>
              <FileDownloadIcon
                sx={{ marginTop: '6px' }}
                className={classes.download}
                onClick={() => {
                  handleDownload(recordId, fileType, fileName);
                }}
              />
            </Grid>
            <Grid item md={3} sx={{ textAlign: 'center' }}>
              <Button
                variant="secondary"
                onClick={open}
                style={{
                  color: '#092682',
                  border: '1px dashed #092682',
                  borderRadius: 6,
                  width: '100%',
                }}
              >
                ReUpload
              </Button>
            </Grid>
            <Grid item md={1} sx={{ textAlign: 'center' }}>
              <DeleteIcon
                sx={{ marginTop: '6px' }}
                className={classes.download}
                onClick={() => {
                  handleDelete(props?.contentDocumentId);
                }}
              />
            </Grid>
            {props?.templateDownload === true && (
              <Grid item xs={3}>
                <Button
                  variant="secondary"
                  onClick={() => window.open(props?.templateURL)}
                  // startIcon={<FileDownloadIcon style={{ color: '#000000' }} />}
                  style={{
                    color: '#092682',
                    border: '1px dashed #092682',
                    borderRadius: 6,
                    width: '100%',
                  }}
                >
                  Template
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item md={12} textAlign="left">
              <Typography
                align="left"
                variant="subtitle2"
                sx={{
                  marginLeft: '65px',
                  color: '#7A869A',
                  marginTop: '10px',
                }}
              >
                {fileName}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
}
