import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Model } from 'survey-core';
import 'survey-core/defaultV2.min.css';
import { Survey } from 'survey-react-ui';
import { createUpdateApplication } from '../../Utils/apiServices';
import KenLoader from '../KenLoader';
import KenStepperFooter from './Components/KenStepperFooter';

const surveyJsonIndividual = {
  logoPosition: 'right',
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'panel',
          name: 'PART I: TO BE COMPLETED BY INDIVIDUAL AND JOINT-INDIVIDUALS ONLY',
          elements: [
            {
              type: 'checkbox',
              name: 'Investor’s Representations. In connection with the Investor’s purchase of the Interest,the Investor makes the following representation on which the Partnership and the GeneralPartner are entitled to rely. The Interest will be held under the following type of ownership[check the applicable box.]:',
              title:
                'Investor’s Representations. In connection with the Investor’s purchase of the Interest,the Investor makes the following representation on which the Partnership and the GeneralPartner are entitled to rely. The Interest will be held under the following type of ownership[check the applicable box.]:',
              choices: [
                'Individual',
                'Joint Individuals [This includes any person acquiring an interest with his or her spouse or partner in a joint capacity, as community property or similar shared interest.]',
              ],
              maxSelectedChoices: 1,
            },
            {
              type: 'checkbox',
              name: 'Accredited Investor Representation. The Investor makes one of the following representations regarding their status as an “accredited investor” (within the meaning of Rule501 under the Securities Act), and has checked the applicable representation:',
              title:
                'Accredited Investor Representation. The Investor makes one of the following representations regarding their status as an “accredited investor”* (within the meaning of Rule501 under the Securities Act), and has checked the applicable representation:',
              choices: [
                '(a) The Investor has a net worth 1 either individually or upon a joint basis with the Investor’s spouse or partner, of at least $1,000,000, or has had individual income in excess of $200,000 for each of the two most recent years, or joint income with the Investor’s spouse or partner in excess of $300,000 in each of those years, and has a reasonable expectation of reaching the same income level in the current year.',
                '(b) The Investor holds, in good standing, a Series 7 General Securities Representative License, a Series 65 Licensed Investment Adviser Representative License, or a Series 82 Private Securities Offering Representative License.',
                '(c) The Investor is a “knowledgeable employee” of the Partnership, which includes executive officers and those employees regularly involved in the investment activities of the Partnership.',
                '(d) The Investor cannot make any of the representation set forth in clauses (a) - (c) above.',
              ],
            },
            {
              type: 'html',
              name: 'question2',
              html: '<p style="font-size: 12px; position: relative;">\n    <span style="display: block; width: 100%; height: 1px; background-color: #999; position: absolute; top: -10px; left: 0;"></span>\n    <span style="font-size: 8px">1</span> In calculating the Investor’s net worth: (i) the Investor’s primary residence shall not be included as an asset; (ii) indebtedness that is secured by the Investor’s primary residence, up to the estimated fair market value of the primary residence at the time of the closing on the Investor’s investment in the Partnership (the “Closing”), shall not be included as a liability (except that if the amount of such indebtedness outstanding at the time of the Closing exceeds the amount outstanding 60 days before such time, other than as a result of the acquisition of the primary residence, the amount of such excess shall be included as a liability); and (iii) indebtedness that is secured by the Investor’s primary residence in excess of the estimated fair market value of the primary residence at the time of the Closing shall be included as a liability. In calculating the Investor’s joint net worth with the Investor’s spouse or partner, the primary residence of the Investor’s spouse or partner (if different from the Investor’s own primary residence) and indebtedness secured by such primary residence should be treated in a similar manner.\n<br>\n<a href="https://www.sec.gov/education/capitalraising/building-blocks/accredited-investor">* Rule 501 under the Securities Act</a>\n</br>\n</p>',
            },
          ],
          title:
            'PART I: TO BE COMPLETED BY INDIVIDUAL AND JOINT-INDIVIDUALS ONLY',
        },
      ],
      title: 'INVESTOR SUITABILITY QUESTIONNAIRE',
      description:
        'Please reach out to your financial/ legal advisor/ consultant should you need any clarification while filling out this questionnaire.',
    },
  ],
};

const surveyJsonEntity = {
  logoPosition: 'right',
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'panel',
          name: 'PART II',
          elements: [
            {
              type: 'checkbox',
              name: 'PART II : Investor’s Representations. In connection with the Investor’s purchase of the Interest, the Investor makes the following representation on which the Partnership and the General Partner are entitled to rely: [a] The Interest will be held under the following type of ownership [Please check the applicable box.]:',
              title:
                'Investor’s Representations. In connection with the Investor’s purchase of the Interest, the Investor makes the following representation on which the Partnership and the General Partner are entitled to rely:',
              description:
                '[a] The Interest will be held under the following type of ownership [Please check the applicable box.]:',
              choices: ['Revocable Trust with grantor(s)', 'Irrevocable Trust'],
              maxSelectedChoices: 1,
            },
            {
              type: 'text',
              name: 'PART II : Please fill in the number of grantors.',
              visibleIf:
                "{PART II : Investor’s Representations. In connection with the Investor’s purchase of the Interest, the Investor makes the following representation on which the Partnership and the General Partner are entitled to rely: [a] The Interest will be held under the following type of ownership [Please check the applicable box.]:} = ['Revocable Trust with grantor(s)']",
              title: 'Please fill in the number of grantors.',
              hideNumber: true,
              enableIf:
                "{PART II : Investor’s Representations. In connection with the Investor’s purchase of the Interest, the Investor makes the following representation on which the Partnership and the General Partner are entitled to rely: [a] The Interest will be held under the following type of ownership [Please check the applicable box.]:} = ['Revocable Trust with grantor(s)']",
              isRequired: true,
              requiredIf:
                "{PART II : Investor’s Representations. In connection with the Investor’s purchase of the Interest, the Investor makes the following representation on which the Partnership and the General Partner are entitled to rely: [a] The Interest will be held under the following type of ownership [Please check the applicable box.]:} = ['Revocable Trust with grantor(s)']",
              inputType: 'number',
            },
            {
              type: 'checkbox',
              name: 'PART II : Accredited Investor Representation: [a] For Irrevocable Trusts: The Investor makes one of the following representations regarding the Investor’s status as an “accredited investor” (within the meaning of Rule 501 under the Securities Act), and has checked the applicable representation:',
              title: 'Accredited Investor Representation:',
              description:
                '[a] For Irrevocable Trusts: The Investor makes one of the following representations regarding the Investor’s status as an “accredited investor”* (within the meaning of Rule 501 under the Securities Act), and has checked the applicable representation:',
              choices: [
                '[i] The Investor is an irrevocable trust (a) not formed for the specific purpose of acquiring the Interest with total assets in excess of $5,000,000 whose purchase is directed by a Person with such knowledge and experience in financial and business matters that such Person is capable of evaluating the merits and risks of the prospective investment or (b) that may be amended or revoked at any time by the grantor(s), the tax benefits of investments made by the trust pass through to the grantor(s), and each grantor is an “accredited investor” (as an entity in which all of the “equity owners” are an accredited investor).',
                '[ii] The Investor cannot make the representation set forth above.',
              ],
              maxSelectedChoices: 1,
            },
            {
              type: 'checkbox',
              name: 'PART II : Accredited Investor Representation: [b] For Revocable Trusts: The Investor makes one of the following representations regarding the Investor’s status as an “accredited investor” (within the meaning of Rule 501 under the Securities Act), and has checked the applicable representation:',
              title:
                '[b] For Revocable Trusts: The Investor makes one of the following representations regarding the Investor’s status as an “accredited investor” (within the meaning of Rule 501 under the Securities Act), and has checked the applicable representation:',
              hideNumber: true,
              choices: [
                '[i] The Investor is a revocable trust in which all of the grantors and trustees qualify under clause (a) in paragraph 2 of Part I (i.e., an accredited individual); OR under clause (a)(i) of paragraph 2 of this Part II (i.e., an accredited irrevocable trust); OR under paragraph 2(a) of Part III (i.e., an accredited IRA); OR under clause (a), (b), (c), or (d) in paragraph 2 of Part IV (i.e., an accredited entity); OR under this clause (b)(i).',
                '[ii] The Investor cannot make the representation set forth above.',
              ],
              maxSelectedChoices: 1,
            },
            {
              type: 'html',
              name: 'question1',
              html: '<p style="font-size: 12px; position: relative;">\n    <span style="display: block; width: 100%; height: 1px; background-color: #999; position: absolute; top: -10px; left: 0;"></span>\n<a href="https://www.sec.gov/education/smallbusiness/exemptofferings/faq?auHash=rh5WfJi9h3wRzP6X2anOmgYLdhPHNuo-3Vw0YNZyR_M#faq2">* Rule 501 under the Securities Act</a>\n</p>',
            },
          ],
          title: 'PART II',
          description:
            'TO BE COMPLETED BY INVESTORS WHO QUALIFY AS IRREVOCABLE OR REVOCABLE TRUSTS',
        },
        {
          type: 'panel',
          name: 'Part III',
          elements: [
            {
              type: 'checkbox',
              name: 'Part III : Investor’s Representations. In connection with the Investor’s purchase of the Interest, the Investor represents that the Interest will be held by an IRA / Keogh / SEP (collectively, “IRA”) (IRA Investors must have the IRA custodian/trustee sign this Agreement on behalf of the IRA). The Interest will be held under the following type of ownership:',
              title:
                'Investor’s Representations. In connection with the Investor’s purchase of the Interest, the Investor represents that the Interest will be held by an IRA / Keogh / SEP (collectively, “IRA”) (IRA Investors must have the IRA custodian/trustee sign this Agreement on behalf of the IRA). The Interest will be held under the following type of ownership:',
              choices: ['IRA', 'Keogh', 'SEP'],
              maxSelectedChoices: 1,
            },
            {
              type: 'checkbox',
              name: 'Part III : Accredited Investor Representation. The Investor makes one of the following representations regarding the Investor’s status as an “accredited investor” (within the meaning of Rule 501 promulgated under the Securities Act), and has checked the applicable representation:',
              title:
                'Accredited Investor Representation. The Investor makes one of the following representations regarding the Investor’s status as an “accredited investor” (within the meaning of Rule 501 promulgated under the Securities Act), and has checked the applicable representation:',
              choices: [
                {
                  value:
                    'The Investor is an IRA account in which all of the beneficiaries are individuals, who have a net worth 2 either individually or upon a joint basis with the Investor’s spouse or partner, of at least $1,000,000, or has had an individual income in excess of $200,000 for each of the two most recent years, or a joint income with the Investor’s spouse or partner in excess of $300,000 in each of those years, and has a reasonable expectation of reaching the same income level in the current year.',
                  text: '[a] The Investor is an IRA account in which all of the beneficiaries are individuals, who have a net worth 2 either individually or upon a joint basis with the Investor’s spouse or partner, of at least $1,000,000, or has had an individual income in excess of $200,000 for each of the two most recent years, or a joint income with the Investor’s spouse or partner in excess of $300,000 in each of those years, and has a reasonable expectation of reaching the same income level in the current year.',
                },
                {
                  value:
                    'The Investor cannot make the representation set forth in clause  (a) above.',
                  text: '[b] The Investor cannot make the representation set forth in clause (a) above.',
                },
              ],
              maxSelectedChoices: 1,
            },
            {
              type: 'text',
              name: 'Part III : List the beneficiaries of the Investor:',
              visibleIf:
                "{Part III : Accredited Investor Representation. The Investor makes one of the following representations regarding the Investor’s status as an “accredited investor” (within the meaning of Rule 501 promulgated under the Securities Act), and has checked the applicable representation:} = ['The Investor is an IRA account in which all of the beneficiaries are individuals, who have a net worth 2 either individually or upon a joint basis with the Investor’s spouse or partner, of at least $1,000,000, or has had an individual income in excess of $200,000 for each of the two most recent years, or a joint income with the Investor’s spouse or partner in excess of $300,000 in each of those years, and has a reasonable expectation of reaching the same income level in the current year.']",
              title: 'List the beneficiaries of the Investor:',
              hideNumber: true,
              enableIf:
                "{Part III : Accredited Investor Representation. The Investor makes one of the following representations regarding the Investor’s status as an “accredited investor” (within the meaning of Rule 501 promulgated under the Securities Act), and has checked the applicable representation:} = ['The Investor is an IRA account in which all of the beneficiaries are individuals, who have a net worth 2 either individually or upon a joint basis with the Investor’s spouse or partner, of at least $1,000,000, or has had an individual income in excess of $200,000 for each of the two most recent years, or a joint income with the Investor’s spouse or partner in excess of $300,000 in each of those years, and has a reasonable expectation of reaching the same income level in the current year.']",
              isRequired: true,
              requiredIf:
                "{Part III : Accredited Investor Representation. The Investor makes one of the following representations regarding the Investor’s status as an “accredited investor” (within the meaning of Rule 501 promulgated under the Securities Act), and has checked the applicable representation:} = ['The Investor is an IRA account in which all of the beneficiaries are individuals, who have a net worth 2 either individually or upon a joint basis with the Investor’s spouse or partner, of at least $1,000,000, or has had an individual income in excess of $200,000 for each of the two most recent years, or a joint income with the Investor’s spouse or partner in excess of $300,000 in each of those years, and has a reasonable expectation of reaching the same income level in the current year.']",
            },
            {
              type: 'html',
              name: 'question2',
              html: '<p style="font-size: 12px; position: relative;">\n    <span style="display: block; width: 100%; height: 1px; background-color: #999; position: absolute; top: -10px; left: 0;"></span>\n    <span style="font-size: 8px">2</span> In calculating the Investor’s net worth: (i) the Investor’s primary residence shall not be included as an asset; (ii) indebtedness that is secured by the Investor’s primary residence, up to the estimated fair market value of the primary residence at the time of the closing on the Investor’s investment in the Partnership (the “Closing”), shall not be included as a liability (except that if the amount of such indebtedness outstanding at the time of the Closing exceeds the amount outstanding 60 days before such time, other than as a result of the acquisition of the primary residence, the amount of such excess shall be included as a liability); and (iii) indebtedness that is secured by the Investor’s primary residence in excess of the estimated fair market value of the primary residence at the time of the Closing shall be included as a liability. In calculating the Investor’s joint net worth with the Investor’s spouse or partner, the primary residence of the Investor’s spouse or partner (if different from the Investor’s own primary residence) and indebtedness secured by such primary residence should be treated in a similar manner.\n</p>\n',
            },
          ],
          title: 'Part III',
          description:
            'TO BE COMPLETED BY INVESTORS WHO QUALIFY AS INVESTMENT RETIREMENT ACCOUNT HOLDERS ONLY',
          showQuestionNumbers: 'onpanel',
          questionStartIndex: '1',
        },
        {
          type: 'panel',
          name: 'Part IV',
          elements: [
            {
              type: 'checkbox',
              name: 'Part IV : Investor’s Representations. In connection with the Investor’s purchase of the Interest, the Investor makes the following representation on which the Partnership and the General Partner are entitled to rely: [a] The Interest will be held under the following type of ownership [Please check the applicable box.]:',
              title:
                'Investor’s Representations. In connection with the Investor’s purchase of the Interest, the Investor makes the following representation on which the Partnership and the General Partner are entitled to rely:',
              description:
                '[a] The Interest will be held under the following type of ownership [Please check the applicable box.]:',
              choices: [
                'Private Tax-Exempt Foundation',
                'Tax-Exempt Endowment',
                'Limited Partnership',
                'General Partnership',
                'C Corporation',
                'S Corporation',
                'Limited Liability Company',
              ],
              showOtherItem: true,
              maxSelectedChoices: 1,
            },
            {
              type: 'checkbox',
              name: 'Part IV : Accredited Investor Representation. The Investor makes one of the followingrepresentations regarding the Investor’s status as an “accredited investor” (within themeaning of Rule 501 under the Securities Act), and has checked the applicablerepresentation [Please check the applicable representation.]:',
              title:
                'Accredited Investor Representation. The Investor makes one of the followingrepresentations regarding the Investor’s status as an “accredited investor” (within themeaning of Rule 501 under the Securities Act), and has checked the applicablerepresentation [Please check the applicable representation.]:',
              choices: [
                '[a] The Investor is a corporation, partnership, limited liability company, partnership, business trust, any Native-American tribal entity, or family office not formed for the specific purpose of acquiring the Interest, or an organization described in Section 501(c)(3) of the Internal Revenue Code of 1986, as amended (the “Code”), in each case with total assets in excess of $5,000,000.',
                '[b] The Investor is a bank, insurance company, investment company registered under the Investment Company Act, a broker or dealer registered pursuant to Section 15 of the U.S. Securities Exchange Act of 1934, as amended, a business development company, a Small Business Investment Company licensed by the U.S. Small Business Administration, a plan with total assets in excess of $5,000,000 established and maintained by a state for the benefit of its employees, or a private business development company as defined in Section 202(a)(22) of the U.S. Investment Advisers Act of 1940, as amended.',
                '[c] The Investor is an employee benefit plan and either all investment decisions are made by a bank, savings and loan association, insurance company, or registered investment advisor, or the Investor has total  assets in excess of $5,000,000 or, if such plan is a self-directed plan, investment decisions are made solely by Persons who are accredited investors.',
                '[d] The Investor is an entity in which all of the equity owners qualify under clause (a) in paragraph 2 of Part I (i.e., an accredited individual); OR under clause (a)(i) of paragraph 2 of Part II (i.e., an accredited irrevocable trust); OR under paragraph 2(a) of Part III (i.e., an accredited IRA); OR under clause (a), (b), (c) of this paragraph 2 of Part IV (i.e., an accredited entity); OR under this clause (d) of this paragraph 2 of Part IV.',
                '[e] The Investor is an SEC- or state-registered investment adviser or any venture capital or mid-sized private fund exempt reporting adviser under the Advisers Act.',
                '[f] The Investor is a family client of a family office qualifying as an accredited investor, whose prospective investment is directed by such family office',
                '[g] The Investor is a rural business investment company as defined in Section 384A of the Consolidated Farm and Rural Development Act',
                '[h] The Investor cannot make any of the representations set forth in clauses  (a)-(g) above.',
              ],
              maxSelectedChoices: 1,
            },
          ],
          title: 'Part IV',
          description:
            'TO BE COMPLETED BY ENTITY INVESTORS ONLY. Trust Investors who have completed Part II of this Agreement and IRA Investors who havecompleted Part III of this Agreement may skip Part IV of this Agreement.',
          showQuestionNumbers: 'onpanel',
          questionStartIndex: '1',
        },
      ],
      title: 'INVESTOR SUITABILITY QUESTIONNAIRE',
      description:
        'Please reach out to your financial/ legal advisor/ consultant should you need any clarification while filling out this questionnaire.',
    },
  ],
  showTitle: false,
  fitToContainer: true,
};

const theme = {
  backgroundImage: '',
  backgroundImageFit: 'auto',
  backgroundImageAttachment: 'scroll',
  backgroundOpacity: 1,
  isPanelless: false,
  cssVariables: {
    '--sjs-general-backcolor': 'rgba(255, 255, 255, 1)',
    '--sjs-general-backcolor-dark': 'rgba(248, 248, 248, 1)',
    '--sjs-general-backcolor-dim': '#FFFFFF',
    '--sjs-general-backcolor-dim-light': 'rgba(249, 249, 249, 1)',
    '--sjs-general-backcolor-dim-dark': 'rgba(243, 243, 243, 1)',
    '--sjs-general-forecolor': 'rgba(0, 0, 0, 0.91)',
    '--sjs-general-forecolor-light': 'rgba(0, 0, 0, 0.45)',
    '--sjs-general-dim-forecolor': 'rgba(0, 0, 0, 0.91)',
    '--sjs-general-dim-forecolor-light': 'rgba(0, 0, 0, 0.45)',
    '--sjs-primary-backcolor': 'rgba(25, 179, 148, 1)',
    '--sjs-primary-backcolor-light': 'rgba(25, 179, 148, 0.1)',
    '--sjs-primary-backcolor-dark': 'rgba(20, 164, 139, 1)',
    '--sjs-primary-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-primary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
    '--sjs-base-unit': '8.8px',
    '--sjs-corner-radius': '4px',
    '--sjs-secondary-backcolor': 'rgba(255, 152, 20, 1)',
    '--sjs-secondary-backcolor-light': 'rgba(255, 152, 20, 0.1)',
    '--sjs-secondary-backcolor-semi-light': 'rgba(255, 152, 20, 0.25)',
    '--sjs-secondary-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-secondary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
    '--sjs-shadow-small': '0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
    '--sjs-shadow-medium': '0px 2px 6px 0px rgba(0, 0, 0, 0.1)',
    '--sjs-shadow-large': '0px 8px 16px 0px rgba(0, 0, 0, 0.1)',
    '--sjs-shadow-inner': 'inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
    '--sjs-border-light': 'rgba(0, 0, 0, 0.09)',
    '--sjs-border-default': 'rgba(0, 0, 0, 0.16)',
    '--sjs-border-inside': 'rgba(0, 0, 0, 0.16)',
    '--sjs-special-red': 'rgba(229, 10, 62, 1)',
    '--sjs-special-red-light': 'rgba(229, 10, 62, 0.1)',
    '--sjs-special-red-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-special-green': 'rgba(25, 179, 148, 1)',
    '--sjs-special-green-light': 'rgba(25, 179, 148, 0.1)',
    '--sjs-special-green-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-special-blue': 'rgba(67, 127, 217, 1)',
    '--sjs-special-blue-light': 'rgba(67, 127, 217, 0.1)',
    '--sjs-special-blue-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-special-yellow': 'rgba(255, 152, 20, 1)',
    '--sjs-special-yellow-light': 'rgba(255, 152, 20, 0.1)',
    '--sjs-special-yellow-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-article-font-xx-large-textDecoration': 'none',
    '--sjs-article-font-xx-large-fontWeight': '700',
    '--sjs-article-font-xx-large-fontStyle': 'normal',
    '--sjs-article-font-xx-large-fontStretch': 'normal',
    '--sjs-article-font-xx-large-letterSpacing': '0',
    '--sjs-article-font-xx-large-lineHeight': '64px',
    '--sjs-article-font-xx-large-paragraphIndent': '0px',
    '--sjs-article-font-xx-large-textCase': 'none',
    '--sjs-article-font-x-large-textDecoration': 'none',
    '--sjs-article-font-x-large-fontWeight': '700',
    '--sjs-article-font-x-large-fontStyle': 'normal',
    '--sjs-article-font-x-large-fontStretch': 'normal',
    '--sjs-article-font-x-large-letterSpacing': '0',
    '--sjs-article-font-x-large-lineHeight': '56px',
    '--sjs-article-font-x-large-paragraphIndent': '0px',
    '--sjs-article-font-x-large-textCase': 'none',
    '--sjs-article-font-large-textDecoration': 'none',
    '--sjs-article-font-large-fontWeight': '700',
    '--sjs-article-font-large-fontStyle': 'normal',
    '--sjs-article-font-large-fontStretch': 'normal',
    '--sjs-article-font-large-letterSpacing': '0',
    '--sjs-article-font-large-lineHeight': '40px',
    '--sjs-article-font-large-paragraphIndent': '0px',
    '--sjs-article-font-large-textCase': 'none',
    '--sjs-article-font-medium-textDecoration': 'none',
    '--sjs-article-font-medium-fontWeight': '700',
    '--sjs-article-font-medium-fontStyle': 'normal',
    '--sjs-article-font-medium-fontStretch': 'normal',
    '--sjs-article-font-medium-letterSpacing': '0',
    '--sjs-article-font-medium-lineHeight': '32px',
    '--sjs-article-font-medium-paragraphIndent': '0px',
    '--sjs-article-font-medium-textCase': 'none',
    '--sjs-article-font-default-textDecoration': 'none',
    '--sjs-article-font-default-fontWeight': '400',
    '--sjs-article-font-default-fontStyle': 'normal',
    '--sjs-article-font-default-fontStretch': 'normal',
    '--sjs-article-font-default-letterSpacing': '0',
    '--sjs-article-font-default-lineHeight': '28px',
    '--sjs-article-font-default-paragraphIndent': '0px',
    '--sjs-article-font-default-textCase': 'none',
    '--sjs-font-size': '13.6px',
    '--sjs-font-questiondescription-color': 'rgba(0, 0, 0, 0.91)',
    '--sjs-font-questiondescription-weight': '600',
  },
  themeName: 'default_exported',
  colorPalette: 'light',
};

export default function SurveyComponent(props) {
  const { applicationState, applicationDispatch } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState();
  console.log(applicationState);

  const survey = new Model(
    applicationState?.applicationData?.account?.Type === 'Individual'
      ? surveyJsonIndividual
      : surveyJsonEntity
  );
  survey.applyTheme(theme);
  survey.onComplete.add((sender, options) => {
    const applicationStateAccount = applicationState?.applicationData?.account;
    applicationStateAccount.Questionnaire_Response__c = JSON.stringify(
      sender.data
    );
    setLoader(true);
    createUpdateApplication({ account: applicationStateAccount })
      .then((res) => {
        if (res?.success) {
          applicationDispatch({
            type: 'update',
            payload: {
              applicationData: res?.data,
            },
          });
          applicationDispatch({ type: 'nextStep' });
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar('Something went wrong', { variant: 'error' });
        setLoader(false);
      });
  });

  survey.showNavigationButtons = false;
  survey.showCompletedPage = false;
  const handleNext = () => {
    survey.completeLastPage();
  };

  const handlePrev = () => {
    applicationDispatch({ type: 'prevStep' });
  };
  return (
    <>
      {loader && <KenLoader />}
      <Box margin={'0px auto'} marginTop={2} marginBottom={4}>
        <Survey model={survey} />
      </Box>
      <KenStepperFooter
        totalSteps={applicationState.totalSteps}
        activeStep={applicationState.activeStep}
        onPrev={handlePrev}
        onNext={handleNext}
        isSave={false}
        applicationStatus={
          applicationState?.applicationData?.application?.applicationStatus
        }
      />
    </>
  );
}
