import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Banner from '../../../../Assets/bannerLong.svg';
import { TABLET_BREAKPOINT } from '../../../../Constants/constant';

const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundImage: `url(${Banner})`,
    // backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    objectFit: 'revert-layer',
    minHeight: '260px',
    padding: '60px 116px 20px 220px',
    marginTop: 28,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: '40px 5px 20px 5px',
      marginTop: 0,
    },
  },
  titleContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      display: 'flex',
      width: '100%',
      // 'justify-content': 'space-between',
      'align-items': 'center',
    },
  },
  programContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      maxWidth: '50%',
    },
  },
  program: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: '0',
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '16px',
      'line-height': '100%',
      display: 'flex',
      'align-items': 'center',
      'white-space': 'normal',
      'text-align': 'right',
    },
    paddingTop: 16,
    paddingBottom: 8,
  },
  details: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      display: 'none',
    },
  },
  nav: {
    cursor: 'pointer',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'white-space': 'nowrap',
    },
  },
  white: {
    color: 'white',
  },
  orange: {
    color: '#E75547',
  },
  heading: {
    color: '#FFEBDA',
  },
}));

export default function KenApplicationFormHeader(props) {
  const {
    formName,
    duration,
    batch,
    endDate,
    intake,
    // academicYear,
    // instituteName,
    // title = "Admission",
  } = props;
  const classes = useStyles();
  //   const { hasBack } = useBack();
  //   const query = new URLSearchParams(useLocation().search);

  const navigate = useNavigate();
  const goToDashboard = () => {
    // if (query.get("new")) {
    // 	hasBack ? navigate(-2) : navigate("/home");
    // } else {
    // 	hasBack ? navigate(-1) : navigate("/home");
    // }
    navigate('/homepage');
  };
  return (
    <Box className={classes.banner}>
      <Grid container spacing={0} md={12} alignItems={'left'}>
        <Grid item md={12}>
          <Typography
            align="left"
            variant="h3"
            marginTop={'50px'}
            sx={{ float: 'center', alignItems: 'left', color: '#ffffff' }}
          >
            Welcome to Turbostart
            <Typography
              align="left"
              fontSize={'12px'}
              marginRight={'295px'}
              marginTop={'4px'}
              sx={{
                float: 'center',
                alignItems: 'left',
                color: '#ffffff',
                fontStyle: 'italic',
              }}
            >
              Need some help? Reach out to us at info@turbostart.co
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
