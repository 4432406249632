import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DoneIcon from '../../Assets/Icons/tickInCircle.png';
import { AuthContext } from '../../Context/authContext';
import KenIcon from '../KenIcon';
import KenLoader from '../KenLoader/index';
import TurboLogo from '../../Assets/Images/turbostart_logo_white.png';

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#0000EE',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function ThankYouPage(props) {
  const {
    icon,
    title = 'Fee Payment',
    description = "Your Fee's has been successfully paid.",
    wrapperClassName,
    titleClass,
    subTitleClass,
    redirection = true,
  } = props;

  const {
    state: { user },
    dispatch,
  } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState();

  const [titleReceived, setTitleReceived] = useState();
  const [descriptionReceived, setDescriptionReceived] = useState();
  const [failed, setFailed] = useState(false);
  const [subtitle, setSubTitle] = useState();

  const classes = useStyles();

  useEffect(() => {
    if (location?.state?.title && location?.state?.description) {
      setTitleReceived(location?.state?.title);
      setDescriptionReceived(location?.state?.description);
      setSubTitle(location?.state?.subTitle);
    }
  }, [location?.state]);

  return (
    <Box
      textAlign="center"
      margin="0 auto"
      width="50%"
      marginTop="20vh"
      className={wrapperClassName}
    >
      {loader && <KenLoader />}
      <Box>
        <img src={TurboLogo} height={'120px'} />
      </Box>
      <Box>
        {failed === false ? (
          <KenIcon iconType="img" icon={DoneIcon}></KenIcon>
        ) : (
          <CancelIcon sx={{ fontSize: 95, color: 'red' }} />
        )}
      </Box>

      <Box mt={3} mb={5}>
        <Typography variant="h4" fontWeight={500} className={titleClass}>
          {titleReceived || title}
        </Typography>
      </Box>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Grid item>
          <Typography
            variant="subtitle3"
            fontWeight={400}
            className={subTitleClass}
          >
            {descriptionReceived}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle3"
            fontWeight={400}
            fontSize={16}
            className={subTitleClass}
          >
            {subtitle}
          </Typography>
        </Grid>
        {redirection && (
          <Grid item>
            <Typography
              variant="subtitle3"
              fontWeight={400}
              fontSize="17px"
              className={subTitleClass}
            >
              Click here to redirect on{' '}
              <span
                onClick={() => {
                  if (location?.state?.link) {
                    window.open(location?.state?.link);
                    dispatch({
                      type: 'LOGOUT',
                      payload: {
                        user: {},
                        token: null,
                      },
                    });
                  } else {
                    navigate('/login');
                    if (!location?.state?.link) {
                      dispatch({
                        type: 'LOGOUT',
                        payload: {
                          user: {},
                          token: null,
                        },
                      });
                    }
                  }
                }}
                className={classes.link}
              >
                {location?.state?.redirect
                  ? location?.state?.redirect
                  : 'Login Form'}
              </span>
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
