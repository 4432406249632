import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import KenIcon from '../../../KenIcon';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { makeStyles } from '@mui/styles';
import { TABLET_BREAKPOINT } from '../../../../Constants/constant';

const useStyles = makeStyles((theme) => ({
  stepperRoot: {
    justifyContent: 'space-evenly',

    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      overflow: 'auto',
      alignItems: 'flex-start',
      marginTop: 8,
    },
  },
  header: {
    position: 'sticky',
    top: 72,
    zIndex: 100,
    padding: theme.spacing(2),
    background: '#FFFFFF',
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  stepContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      flexDirection: 'column',
    },
  },
  iconContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      paddingRight: 0,
      paddingBottom: 8,
    },
  },
}));

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const StepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: theme.palette.primary.main,
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#52C15A',
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

StepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

function StepIcon(props) {
  const { active, completed, className, completedStepIcon, stepIcon } = props;
  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? (
        completedStepIcon ? (
          <KenIcon icon={completedStepIcon} />
        ) : (
          <CheckCircleOutlineOutlinedIcon className="QontoStepIcon-completedIcon" />
        )
      ) : stepIcon ? (
        <KenIcon icon={stepIcon} />
      ) : (
        <CircleOutlinedIcon />
      )}
    </StepIconRoot>
  );
}

StepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default function CustomizedSteppers(props) {
  const { steps, alternativeLabel = false, connectorProps, activeStep } = props;
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <Stepper
        classes={{ root: classes.stepperRoot }}
        alternativeLabel={alternativeLabel}
        activeStep={activeStep}
        connector={
          connectorProps?.display ? <Connector {...connectorProps} /> : <></>
        }
      >
        {steps.map((step, index) => (
          <Step key={step?.stepId}>
            <StepLabel
              className={classes.stepContainer}
              StepIconComponent={(labelProps) => {
                return (
                  <StepIcon
                    {...labelProps}
                    stepIcon={step?.icon}
                    completedStepIcon={step.completedStepIcon}
                    className={classes.iconContainer}
                  />
                );
              }}
            >
              <Typography variant="subtitle1">{step?.stepName}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
