import { REGEX } from '../../Constants/constant';

export default function getValidation(validationType) {
  switch (validationType) {
    case 'email':
      return REGEX.EMAIL;

    case 'alpha':
      return REGEX.NAME;

    case 'alphaNumeric':
      return REGEX.MIXED;

    case 'alphaAddress':
      return REGEX.MIXED_ADDRESS;

    case 'number':
      return REGEX.ONLYNUMBER;

    case 'numberWithDecimal':
      return REGEX.NUMBERWITHDECIMAL;

    case 'url':
      return REGEX.URL;

    default:
      return '';
  }
}
