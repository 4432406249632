import { useTheme } from '@emotion/react';
import { Box, CssBaseline, useMediaQuery } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ThankYouPage from '../../Components/ThankYou';
import KenLoader from '../../Components/KenLoader';
import KenPrivateRoute from '../../Components/KenPrivateRoute';
import {
  PERMISSIONS,
  ROLES,
  TABLET_BREAKPOINT,
} from '../../Constants/constant';
import routes from '../../Constants/routes';
import { useAppContext } from '../../Context/appContext';
import { AuthContext } from '../../Context/authContext';
import { ErrorFallback } from '../../Utils/ErrorBoundary';
import { getAxiosInstance, getConfig } from '../../Utils/apiServices';
import ApplicationFormNew from '../ApplicationForm/Application';
import Login from '../Auth/Login';
import LoginOTP from '../Auth/Login/LoginOTP';
import VerifiedOTP from '../Auth/SignUp/VerifiedOTP';
import VerifyEmail from '../Auth/SignUp/VerifyEmail';

export default function MainLayout(props) {
  const [open, setOpen] = React.useState(true);
  const {
    dispatch: dispatchApp,
    state: { isAuthenticated, user, search },
  } = useContext(AuthContext);

  const theme = useTheme();
  const screenSize = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  const {
    state: { isLoading = false },
    dispatch,
  } = useAppContext();

  const [config, setConfig] = useState();
  const location = useLocation();
  const isForm =
    location.pathname === '/form' ||
    location.pathname === '/application' ||
    location.pathname === '/thankYou' ||
    location.pathname === '/events' ||
    location.pathname === '/homepage';

  React.useEffect(() => {
    getConfig()
      .then((res) => {
        dispatch({ type: 'updateConfig', value: res[0]?.config });
        getAxiosInstance(res);
        setConfig(res[0]?.config);
      })
      .catch((err) => {
        console.log('error in config', err);
      });
  }, []);

  return (
    <>
      <div style={!isForm ? { height: '100%' } : {}}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
            window.location.reload();
          }}
        >
          <SnackbarProvider autoHideDuration={5000}>
            <Box sx={{ display: 'flex', height: '100%' }}>
              <CssBaseline />
              {!config ? (
                <KenLoader />
              ) : (
                <>
                  {isLoading && <KenLoader />}
                  {isAuthenticated && !isForm && (
                    <>
                      {/* <SideNavigation
                        open={open}
                        handleDrawerClose={handleDrawerClose}
                        handleDrawerOpen={handleDrawerOpen}
                        setSwipeableDrawer={setSwipeableDrawer}
                        swipeableDrawer={swipeableDrawer}
                        setOpen={setOpen}
                      />
                      <MenuAppBar
                        open={open}
                        handleDrawerOpen={handleDrawerOpen}
                        setSwipeableDrawer={setSwipeableDrawer}
                      /> */}
                    </>
                  )}
                  <Box
                    component="main"
                    sx={{
                      flexGrow: 1,
                      margin: isMobileScreen
                        ? '0px'
                        : !isAuthenticated || isForm
                        ? '0px'
                        : screenSize
                        ? '80px - 28px'
                        : '80px 16px',
                      height: !isAuthenticated ? '100%' : 'calc(100% - 160px)',
                      width: isMobileScreen ? '100%' : undefined,
                      paddingTop: isMobileScreen ? '65px' : undefined,
                      //  Note: added following so for main screen the gird is splitted on 12 grid after subtracting side navbar
                      maxWidth:
                        !isMobileScreen && isAuthenticated && !isForm
                          ? open
                            ? 'calc(100% - 260px)'
                            : 'calc(100% - 145px)'
                          : undefined,
                    }}
                  >
                    <Routes>
                      <Route
                        path={'/application'}
                        element={
                          isAuthenticated ? (
                            <ApplicationFormNew />
                          ) : (
                            <Navigate to="/login" />
                          )
                        }
                      />
                      <Route
                        path={routes.login}
                        element={
                          isAuthenticated ? (
                            search !== null && search !== '' ? (
                              <Navigate to={`/courses?search=${search}`} />
                            ) : (
                              <Navigate to="/application" />
                            )
                          ) : (
                            <Login />
                          )
                        }
                      />
                      <Route
                        path={routes.loginOTP}
                        element={
                          isAuthenticated ? (
                            <Navigate to="/application" />
                          ) : (
                            <LoginOTP />
                          )
                        }
                      />
                      <Route
                        path={routes.verifyEmail}
                        element={
                          isAuthenticated ? (
                            <Navigate to="/application" />
                          ) : (
                            <VerifyEmail />
                          )
                        }
                      />
                      <Route
                        path={routes.verifiedOTP}
                        element={
                          isAuthenticated ? (
                            <Navigate to="/application" />
                          ) : (
                            <VerifiedOTP />
                          )
                        }
                      />

                      <Route
                        exact
                        path={`/thankYou`}
                        redirectTo="/application"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_THANKYOU}
                          >
                            <ThankYouPage />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        path={`*`}
                        element={
                          isAuthenticated ? (
                            <Navigate to="/application" />
                          ) : (
                            <Login />
                          )
                        }
                      />
                    </Routes>
                  </Box>
                </>
              )}
            </Box>
          </SnackbarProvider>
        </ErrorBoundary>
      </div>
    </>
  );
}
