import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import { useApplicationContext } from '../../Context/applicationContext';
import KenStepperFooter from './Components/KenStepperFooter';
import KenSummaryStep from '../KenSummaryPage';

import { useAppContext } from '../../Context/appContext';
import DocumentUpload from './DocumentUpload';
import FormStep from './Form';
import FundDetails from './FundDetails';
import { createUpdateApplication } from '../../Utils/apiServices';
import Survey from './Survey';
import SurveyComponent from './Survey';

// Any special cases like payments,summary based on the stepType will be handled
// NOTE: These special steps should be coupled with any other steps

const useStyles = makeStyles((theme) => ({
  thankYouContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '80%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  title: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '20px',
      'line-height': '23px',
      color: '#000000',
    },
  },
  subTitle: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'line-height': '150%',
      'text-align': 'center',
      color: '#505F79',
    },
  },
  link: {
    color: '#0000EE',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function Stepper({ step }) {
  const { applicationState, applicationDispatch } = useApplicationContext();
  const formRef = useRef();
  console.log('Stepper');

  // based on stepType return components

  const getStepContent = () => {
    console.log('Stepper : getStepContent');

    switch (step?.stepType) {
      case 'payment':
        return <PaymentStep />;

      case 'summary':
        return (
          <SummaryStep
            applicationState={applicationState}
            applicationDispatch={applicationDispatch}
          />
        );
      case 'documentUpload':
        return (
          <DocumentUpload
            applicationState={applicationState}
            applicationDispatch={applicationDispatch}
          />
        );

      case 'fundDetails':
        return (
          <FundDetails
            applicationState={applicationState}
            applicationDispatch={applicationDispatch}
          />
        );

      case 'survey':
        return (
          <SurveyComponent
            applicationState={applicationState}
            applicationDispatch={applicationDispatch}
          />
        );

      default:
        return <FormStep step={step} ref={formRef} />;
    }
  };

  return (
    <>
      {/* render step by id */}
      {/* <KenStepperHeader activeStep={step} /> */}

      <Box m={2} alignItems="center" justifyContent="center">
        {getStepContent()}
      </Box>
    </>
  );
}

const PaymentStep = () => {
  return <>Payment Step</>;
};

const SummaryStep = (props) => {
  const { applicationState, applicationDispatch } = props;
  const { dispatch: dispatchAppContext } = useAppContext();

  console.log(applicationState);

  const classes = useStyles();
  const navigate = useNavigate();

  const handlePrev = () => {
    applicationDispatch({ type: 'prevStep' });
  };
  const handleNext = () => {
    let data = applicationState?.applicationData?.account;
    data.Form_Submitted__c = true;
    data.Primary_Contact__c =
      applicationState?.applicationData?.account?.Primary_Contact__c;
    dispatchAppContext({ type: 'udpateLoading', value: true });

    createUpdateApplication({
      account: { ...data },
    })
      .then((res) => {
        dispatchAppContext({ type: 'udpateLoading', value: false });
        // on success update context data and active step
        if (res?.success) {
          // update context
          applicationDispatch({
            type: 'update',
            payload: { applicationData: res.data },
          });
          navigate('/thankYou', {
            state: {
              title: 'Welcome Aboard!',
              description: `Your registration is complete!`,
              subTitle: `Check your email for next steps.`,
              link: 'https://turbostart.co/',
              redirect: 'turbostart.co',
            },
          });
          applicationDispatch({ type: 'nextStep' });
          applicationDispatch({ type: 'showHeader' });
        }
      })
      .catch((err) => {
        dispatchAppContext({ type: 'udpateLoading', value: false });
        //alert user of failure > try again
        console.log('Updating data failed. Try again');
      });
  };
  return (
    <>
      <Box sx={{ width: '80vw', margin: '0 auto' }}>
        <KenSummaryStep
          formJson={applicationState.steps}
          data={applicationState.applicationData}
          applicationState={applicationState}
        />
      </Box>
      <KenStepperFooter
        totalSteps={applicationState.totalSteps}
        activeStep={applicationState.activeStep}
        onPrev={handlePrev}
        onNext={handleNext}
        isSave={false}
        applicationStatus={
          applicationState?.applicationData?.application?.applicationStatus
        }
      />
    </>
  );
};
