import { useTheme } from '@emotion/react';
import PrintIcon from '@mui/icons-material/Print';
import { Box, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print/lib';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import { FormContext } from '../../Context/formContext';
import { getSplitName } from '../../Utils/Helpers/stringHelper';
import SummaryToPrint from './summaryToPrint';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: 'calc(100% - 16px)',
    paddingTop: theme.spacing(7.125),
    overflow: 'auto',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      paddingTop: theme.spacing(3),
    },
  },
  stepLabel: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  sectionLabel: {
    fontWeight: 400,
  },
  sectionSubLabel: {
    color: '#7A869A',
    fontWeight: 400,
  },
  footerContainer: {
    position: 'absolute',
    bottom: '0px',
    padding: '16px',
    paddingRight: '223px',
    minHeight: 76,
    // height: "100px",
    left: '0px',
    right: '0px',
    overflow: 'hidden',
    background: '#FFFFFF',
    boxShadow:
      '10px 0px 20px rgba(0, 0, 0, 0.04), 2px 0px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    // backgroundColor: "blue",
  },
  container: {
    position: 'absolute',
    top: '270px',
    bottom: '0px',
    left: '0px',
    right: '0px',
  },
  paymentContainer: {
    padding: '16px 223px',
  },
  fieldNameValue: {
    wordBreak: 'break-word',
    fontWeight: 400,
  },
  fieldName: {
    color: '#7A869A',
  },
  fieldValue: {
    color: '#061938',
  },
  fieldContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      flexDirection: 'column',
      'align-items': 'flex-start',
      'justify-content': 'flex-start',
      'max-width': '100%',
      display: 'flex',
      width: '100%',
      'padding-top': '0 !important',
    },
  },
  fieldKeyContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '40%',
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'line-height': '150%',
      'text-transform': 'capitalize',
      color: '#7A869A',
    },
  },
  fieldValueContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '60%',
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'line-height': '150%',
      color: '#061938',
    },
  },
  divider: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: '5px auto 16px 0',
    },
  },
  dividerContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: '5px',
    },
  },
  dividerWrapper: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: '0px',
      paddingTop: '0 !important',
    },
  },
}));

const KenSummary = React.forwardRef((props, ref) => {
  const { formJson, data, applicationState } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { formState, formDispatch } = useContext(FormContext);
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkFormOptions = (value) => {
    let combinedValues = [];
    Object.values(applicationState.formOptions).map((el) => {
      if (Array.isArray(el)) {
        combinedValues.push(...el);
      }
    });
    if (combinedValues.some((el) => el.value === value)) {
      return combinedValues.find((el) => el.value === value)?.label;
    } else if (value === 'true' || value === 'false') {
      return value === 'true' ? 'Yes' : 'No';
    } else {
      return value;
    }
  };

  const getFieldValue = (fieldName) => {
    try {
      //convert names to executable to get value
      const name = 'data[' + fieldName + ']';
      const keysArray = getSplitName(name, '', true) || [];
      let str = '';
      keysArray.map((item, index) => {
        if (index === 0) {
          str = item;
        } else {
          if (isNaN(item)) {
            str = `${str}["${item}"] && ${str}["${item}_select"] || ${str}["${item}"] && ${str}["${item}"]`;
          } else {
            str = `${str}[${item}] && ${str}[${item}]`;
          }
        }
      });
      return checkFormOptions(eval(str)); //covert string to executable
    } catch {
      console.log('error in eval');
    }
  };

  const getNestedData = (name, structure) => {
    const flatStructureData = structure.map((a) => {
      return { name: a?.name, label: a?.label };
    });
    let dataByName;
    if (/\d/.test(name)) {
      dataByName = getFieldValue(name);
    } else {
      dataByName = data[name] || [];
    }
    const allData = dataByName?.map((el) => {
      let obj = {};
      flatStructureData?.forEach((item) => {
        console.log(item.name);
        obj[item.label] =
          item.name.includes('semesterNumber') ||
          item.name.includes('yearNumber')
            ? el[item?.name]?.replace(/\D/g, '')
            : el[item?.name];
      });
      return obj;
    });
    return allData;
  };

  console.log(data);

  return (
    <div ref={ref}>
      <Box p={2}>
        <Typography
          align="left"
          marginBottom={isMobileScreen ? 0 : theme.spacing(4)}
          className={classes.stepLabel}
          fontSize="20px"
        >
          Summary
        </Typography>

        <Box pt={1}>
          {formJson?.map((step) => {
            return (
              <>
                <Grid container spacing={2}>
                  {step?.sections?.map((section, index) => {
                    return (
                      <>
                        {section.sectionVisible === true && (
                          <>
                            {section.fields.some((field) => {
                              const fieldValue = getFieldValue(field.name);
                              return (
                                fieldValue !== null && fieldValue !== undefined
                              );
                            }) && (
                              <Grid item xs={12}>
                                <Box>
                                  {section?.sectionName && (
                                    <Typography
                                      align="left"
                                      marginBottom={
                                        isMobileScreen
                                          ? theme.spacing(1)
                                          : theme.spacing(4)
                                      }
                                      className={classes.sectionLabel}
                                      fontSize="20px"
                                    >
                                      {section?.sectionName}
                                    </Typography>
                                  )}
                                  {section?.subSectionName && (
                                    <Typography
                                      align="left"
                                      marginBottom={
                                        isMobileScreen
                                          ? theme.spacing(1)
                                          : theme.spacing(4)
                                      }
                                      className={classes.sectionSubLabel}
                                      variant="body2"
                                    >
                                      {section?.subSectionName}
                                    </Typography>
                                  )}
                                </Box>
                              </Grid>
                            )}
                            {/* <Box key={section.sectionId}> */}
                            {/* <Grid container spacing={6} rowSpacing={4}> */}
                            {section.fields.map((field, fieldIndex) => {
                              return field?.metaData?.addMultiple === true ? (
                                <Grid
                                  item
                                  container
                                  xs={12}
                                  md={12}
                                  spacing={3}
                                >
                                  <>
                                    {getNestedData(
                                      field.name,
                                      field.fieldsArray
                                    )?.map((nestedField, nestedFieldIndex) => {
                                      return (
                                        nestedField &&
                                        Object.keys(nestedField).map(
                                          (keyName, keyIndex) => {
                                            console.log(
                                              keyName,
                                              nestedField[keyName],
                                              nestedField
                                            );
                                            if (
                                              nestedField[keyName] !== null &&
                                              keyName !== 'undefined'
                                            )
                                              return (
                                                <>
                                                  <Grid
                                                    item
                                                    container
                                                    xs={12}
                                                    md={6}
                                                    spacing={1}
                                                    className={
                                                      classes.fieldContainer
                                                    }
                                                  >
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={12}
                                                      md={6}
                                                      textAlign="left"
                                                      className={
                                                        classes.fieldKeyContainer
                                                      }
                                                    >
                                                      <Typography
                                                        className={`${classes.fieldName} ${classes.fieldNameValue}`}
                                                        variant="h5"
                                                      >
                                                        {keyName}
                                                      </Typography>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={12}
                                                      md={6}
                                                      textAlign="left"
                                                      className={
                                                        classes.fieldValueContainer
                                                      }
                                                    >
                                                      <Typography
                                                        className={`${classes.fieldValue} ${classes.fieldNameValue}`}
                                                        variant="h5"
                                                      >
                                                        {nestedField[keyName]}
                                                      </Typography>
                                                    </Grid>
                                                  </Grid>
                                                  {Object.keys(nestedField)
                                                    ?.length -
                                                    1 ===
                                                    keyIndex &&
                                                    getNestedData(
                                                      field.name,
                                                      field.fieldsArray
                                                    )?.length -
                                                      1 !==
                                                      nestedFieldIndex && (
                                                      <Grid
                                                        item
                                                        xs={12}
                                                        className={
                                                          classes.dividerContainer
                                                        }
                                                      >
                                                        <Divider
                                                          className={
                                                            classes.divider
                                                          }
                                                        />
                                                      </Grid>
                                                    )}
                                                </>
                                              );
                                          }
                                        )
                                      );
                                    })}
                                  </>
                                  {/* </Typography> */}
                                  {/* </Grid> */}
                                </Grid>
                              ) : field?.fieldId ? (
                                <>
                                  {getFieldValue(field.name) && (
                                    <Grid
                                      item
                                      container
                                      xs={12}
                                      md={6}
                                      spacing={2}
                                      className={classes.fieldContainer}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        textAlign="left"
                                        className={classes.fieldKeyContainer}
                                      >
                                        <Typography
                                          className={`${classes.fieldName} ${classes.fieldNameValue}`}
                                          variant="h5"
                                        >
                                          {field?.label}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        textAlign="left"
                                        className={classes.fieldValueContainer}
                                      >
                                        <Typography
                                          className={`${classes.fieldValue} ${classes.fieldNameValue}`}
                                          variant="h5"
                                        >
                                          {field && getFieldValue(field.name)}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {field?.ui?.divider && (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      className={classes.dividerContainer}
                                    >
                                      <Divider className={classes.divider} />
                                    </Grid>
                                  )}
                                </>
                              ) : (
                                <>
                                  {field?.options.map((el) => {
                                    return (
                                      <>
                                        {getFieldValue(
                                          `${field.name}[${el.fieldId}]`
                                        ) && (
                                          <Grid
                                            item
                                            container
                                            xs={12}
                                            md={6}
                                            spacing={2}
                                            className={classes.fieldContainer}
                                          >
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              md={6}
                                              textAlign="left"
                                              className={
                                                classes.fieldKeyContainer
                                              }
                                            >
                                              <Typography
                                                className={`${classes.fieldName} ${classes.fieldNameValue}`}
                                                variant="h5"
                                              >
                                                {el?.label}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              md={6}
                                              textAlign="left"
                                              className={
                                                classes.fieldValueContainer
                                              }
                                            >
                                              <Typography
                                                className={`${classes.fieldValue} ${classes.fieldNameValue}`}
                                                variant="h5"
                                              >
                                                {el &&
                                                  getFieldValue(
                                                    `${field.name}[${el.fieldId}]`
                                                  )}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        )}
                                        {field?.ui?.divider && (
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            className={classes.dividerContainer}
                                          >
                                            <Divider
                                              className={classes.divider}
                                            />
                                          </Grid>
                                        )}
                                      </>
                                    );
                                  })}
                                </>
                              );
                            })}
                            <Grid
                              item
                              xs={12}
                              className={classes.dividerWrapper}
                            >
                              <Box
                                mt={3}
                                mb={6}
                                className={classes.dividerContainer}
                              >
                                <Divider className={classes.divider} />
                              </Box>
                            </Grid>
                          </>
                        )}
                      </>
                    );
                  })}
                </Grid>
              </>
            );
          })}
          <Grid container mb={10}>
            {data?.funds?.map((el) => {
              return (
                <>
                  <Grid item md={12}>
                    <Typography
                      align="left"
                      marginBottom={
                        isMobileScreen ? theme.spacing(1) : theme.spacing(4)
                      }
                      className={classes.sectionLabel}
                      fontSize="20px"
                    >
                      {el?.Funding_Program__r?.Name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    spacing={2}
                    className={classes.fieldContainer}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      textAlign="left"
                      className={classes.fieldKeyContainer}
                    >
                      <Typography
                        className={`${classes.fieldName} ${classes.fieldNameValue}`}
                        variant="h5"
                      >
                        {/* {field?.label} */} Amount
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      textAlign="left"
                      className={classes.fieldValueContainer}
                    >
                      <Typography
                        className={`${classes.fieldValue} ${classes.fieldNameValue}`}
                        variant="h5"
                      >
                        {el.Currency_Type__c}{' '}
                        {parseFloat(el.Amount__c).toLocaleString('en-US')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    spacing={2}
                    className={classes.fieldContainer}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      textAlign="left"
                      className={classes.fieldKeyContainer}
                    >
                      <Typography
                        className={`${classes.fieldName} ${classes.fieldNameValue}`}
                        variant="h5"
                      >
                        {/* {field?.label} */} Amount In words
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      textAlign="left"
                      className={classes.fieldValueContainer}
                    >
                      <Typography
                        className={`${classes.fieldValue} ${classes.fieldNameValue}`}
                        variant="h5"
                      >
                        {/* {field && getFieldValue(field.name)} */}{' '}
                        {el.Amount_In_Words__c}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.dividerWrapper}>
                      <Box mt={3} mb={6} className={classes.dividerContainer}>
                        <Divider className={classes.divider} />
                      </Box>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </div>
  );
});

const KenSummaryStep = (props) => {
  const componentRef = useRef();

  return (
    <div>
      <ReactToPrint
        pageStyle="size: 11.0in 8.5in; !important;"
        trigger={() => (
          <Grid container justifyContent="space-between">
            <Grid item></Grid>
            <Grid item alignItems="right" mb={-5}>
              {/* <PrintIcon /> */}
            </Grid>
          </Grid>
        )}
        content={() => componentRef.current}
      />
      <KenSummary {...props} />
      <div style={{ display: 'none' }}>
        <SummaryToPrint ref={componentRef} {...props} />
      </div>
    </div>
  );
};

export default KenSummaryStep;
