import React, { useEffect, useReducer } from 'react';

export const initialState = {
  isAuthenticated: false,
  user: null,
  search: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        search: action.payload.search,
      };
    case 'LOGOUT':
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        search: null,
      };
    default:
      return state;
  }
};

export const AuthContext = React.createContext();

export const AuthContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  //TODO: Enable for loggin in user once token is store in local storage
  useEffect(() => {
    const user = JSON?.parse(localStorage?.getItem('user'));
    if (user) {
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
