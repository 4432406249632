export const Entity_without_questionnare = [
  {
    stepId: 'Company Details',
    stepName: 'Basic Details',
    showStepName: false,
    stepVisible: true,
    stepPosition: 0,
    stepType: 'form',
    sections: [
      {
        apiKeyId: 'application',
        sectionId: 'Investment Details',
        sectionName: 'Investment Details',
        subSectionName: '',
        sectionPosition: 1,
        sectionVisible: true,
        sectionHelp: 'Fields marked with * are required',
        sectionType: 'form',
        fields: [
          {
            fieldId: 'account[Type]',
            required: true,
            name: 'account[Type]',
            label: 'In what capacity will you be making your investment?',
            options: [
              {
                value: 'Individual',
                label: 'Individual',
              },
              {
                value: 'Legal Entity',
                label: 'Legal Entity',
              },
            ],
            type: 'investment',
            ui: {
              gridType: 'fullRow',
            },
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 200,
                message: 'This field cannot be more than 200 characters',
              },
            },
          },
        ],
      },
      {
        apiKeyId: 'application',
        sectionId: 'CompanyDetails1',
        sectionName: 'Basic Details',
        subSectionName: '',
        sectionPosition: 1,
        sectionVisible: true,
        sectionHelp: 'Fields marked with * are required',
        sectionType: 'form',
        fields: [
          {
            fieldId: 'account[Name]',
            required: true,
            name: 'account[Name]',
            label: 'Name of the Entity',
            type: 'input',
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 200,
                message: 'This field cannot be more than 200 characters',
              },
            },
            ui: {
              gridType: 'fullRow',
            },
          },
          {
            fieldId: 'investors[0][Email]',
            required: true,
            name: 'investors[0][Email]',
            label: 'Primary Email Address',
            type: 'input',
            validationType: 'email',
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 250,
                message: 'This field cannot be more than 250 characters',
              },
            },
          },
          {
            fieldId: 'investors[0][Secondary_Email_Address__c]',
            required: false,
            name: 'investors[0][Secondary_Email_Address__c]',
            label: 'Secondary Email Address',
            type: 'input',
            validationType: 'email',
          },

          {
            fieldId: 'account[Website]',
            name: 'account[Website]',
            label: 'Website',
            validationType: 'url',
            type: 'input',
            // validations: {
            //   required: 'This field is required',
            //   maxLength: {
            //     value: 250,
            //     message: 'This field cannot be more than 250 characters',
            //   },
            // },
          },
          {
            fieldId: 'account[Phone]',
            required: true,
            name: 'account[Phone]',
            label: 'Phone',
            type: 'phone',
            validations: {
              required: 'This field is required',
              pattern: {
                value: '',
                message: 'Please input a valid value',
              },
            },
          },
          {
            name: 'account',
            type: 'address',
            options: [
              {
                fieldId: 'Country_Of_Registration__c',
                required: true,
                name: 'Country_Of_Registration__c',
                isPartOfCollection: true,
                label: 'Country of Registration/Incorporation',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
              },
            ],
          },

          {
            fieldId: 'account[Legal_Form__c]',
            required: true,
            name: 'account[Legal_Form__c]',
            label: 'Type/Nature of Entity or Organization',
            type: 'investment',
            // type: 'input',
            options: [
              {
                label: 'Private Limited',
                value: 'Private Limited',
              },
              {
                label: 'Corporation',
                value: 'Corporation',
              },
              {
                label: 'LLC',
                value: 'LLC',
              },
              { label: 'Others', value: 'Others' },
            ],
            validations: {
              required: 'This field is required',
              pattern: {
                value: '',
                message: 'Please input a valid value',
              },
            },
          },
          {
            fieldId: 'account[Legal_Form_Other__c]',
            required: true,
            name: 'account[Legal_Form_Other__c]',
            label: 'Legal Form Others',
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: '',
                message: 'Please input a valid value',
              },
            },
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'account[Legal_Form__c]',
                expectedValue: 'Others',
                type: 'LEAF',
              },
            },
          },
          {
            fieldId: 'account[Authorized_Signatory__c]',
            required: true,
            name: 'account[Authorized_Signatory__c]',
            label: 'Name of the Authorized Signatory',
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: '',
                message: 'Please input a valid value',
              },
            },
          },
          {
            fieldId: 'account[Authorized_Signatory_Designation__c]',
            required: true,
            name: 'account[Authorized_Signatory_Designation__c]',
            label: 'Designation of the Authorized Signatory',
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: '',
                message: 'Please input a valid value',
              },
            },
          },
          {
            fieldId: 'account[Emails_In_CC__c]',
            name: 'account[Emails_In_CC__c]',
            label:
              'Please let us know if you would like us to keep anyone else in the loop while sending out communications including Investors’ Report and updates',
            type: 'input',
            validations: {
              pattern: {
                value: '',
                message: 'Please input a valid value',
              },
            },
            ui: {
              gridType: 'fullRow',
            },
          },
        ],
      },
      {
        apiKeyId: 'application',
        sectionId: 'Address',
        sectionName: 'Registered Address',
        subSectionName: '',
        sectionPosition: 1,
        sectionVisible: true,
        sectionHelp: 'Fields marked with * are required',
        sectionType: 'form',
        fields: [
          {
            name: 'account',
            type: 'address',
            options: [
              {
                fieldId: 'BillingCountry',
                required: true,
                name: 'BillingCountry',
                label: 'Country',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
              },
              {
                fieldId: 'BillingState',
                required: true,
                name: 'BillingState',
                label: 'State',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
              },
              {
                fieldId: 'BillingCity',
                required: true,
                name: 'BillingCity',
                label: 'Town/City',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
              },
            ],
          },

          {
            fieldId: 'account[BillingStreet]',
            required: true,
            name: 'account[BillingStreet]',
            label: 'Address',
            type: 'input',
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 200,
                message: 'This field cannot be more than 200 characters',
              },
              pattern: {
                value: '',
                message: 'Please input a valid value',
              },
            },
          },
          {
            fieldId: 'account[BillingPostalCode]',
            required: true,
            name: 'account[BillingPostalCode]',
            label: 'Pin Code',
            type: 'input',
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 6,
                message: 'This field cannot be more than 6 characters',
              },
            },
          },
        ],
      },
      // {
      //   apiKeyId: 'application',
      //   sectionId: 'Shareholder Details',
      //   sectionName: 'Ultimate Beneficial Owner',
      //   subSectionName:
      //     '(Individuals holding directly or indirectly 20% or more stake or voting rights in the entity)',
      //   sectionPosition: 1,
      //   sectionVisible: true,
      //   sectionHelp: 'Fields marked with * are required',
      //   sectionType: 'form',
      //   fields: [
      //     {
      //       fieldId: 'investors',
      //       name: 'investors',
      //       label: '',
      //       type: 'array',
      //       metaData: {
      //         addMultiple: true,
      //         minItems: 1,
      //       },
      //       fieldsArray: [
      //         {
      //           fieldId: 'FirstName',
      //           required: true,
      //           name: 'FirstName',
      //           label: 'First Name',
      //           type: 'input',
      //           validationType: 'alpha',
      //           validations: {
      //             required: 'This field is required',
      //             maxLength: {
      //               value: 80,
      //               message: 'This field cannot be more than 80 characters',
      //             },
      //             pattern: {
      //               value: '',
      //               message: 'Please input a valid value',
      //             },
      //           },
      //         },
      //         {
      //           fieldId: 'LastName',
      //           required: true,
      //           name: 'LastName',
      //           label: 'Last Name',
      //           type: 'input',
      //           validationType: 'alpha',
      //           validations: {
      //             required: 'This field is required',
      //             maxLength: {
      //               value: 80,
      //               message: 'This field cannot be more than 80 characters',
      //             },
      //             pattern: {
      //               value: '',
      //               message: 'Please input a valid value',
      //             },
      //           },
      //         },
      //         {
      //           fieldId: 'Shareholding_Percentage__c',
      //           required: true,
      //           name: 'Shareholding_Percentage__c',
      //           label: 'Share Holding Percentage',
      //           type: 'numberWithDecimal',
      //           validations: {
      //             required: 'This field is required',
      //             maxLength: {
      //               value: 5,
      //               message: 'This field cannot be more than 5 characters',
      //             },
      //             pattern: {
      //               value: '',
      //               message: 'Please input a valid value',
      //             },
      //           },
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
  },
  {
    stepId: 'fundDetails',
    stepName: 'Fund Details',
    showStepName: false,
    stepVisible: true,
    stepPosition: 1,
    stepType: 'fundDetails',
    sections: [],
  },
  {
    stepId: 'additionalQuestions',
    stepName: 'KYC Documents',
    showStepName: false,
    stepVisible: true,
    stepPosition: 1,
    stepType: 'documentUpload',
    sections: [],
  },

  {
    stepId: 'Company Details',
    stepName: 'Bank Account Details',
    showStepName: false,
    stepVisible: true,
    stepPosition: 0,
    stepType: 'form',
    sections: [
      {
        apiKeyId: 'application',
        sectionId: 'Bank Account Details',
        sectionName: 'Bank Account Details',
        subSectionName: '',
        sectionPosition: 1,
        sectionVisible: true,
        sectionHelp: 'Fields marked with * are required',
        sectionType: 'form',
        fields: [
          {
            fieldId: 'account[Bank_Account_Holder_Name__c]',
            
            name: 'account[Bank_Account_Holder_Name__c]',
            label: 'Account Holder Name',
            type: 'input',
            validations: {
              
              maxLength: {
                value: 200,
                message: 'This field cannot be more than 200 characters',
              },
            },
            ui: {
              gridType: 'fullRow',
            },
          },

          {
            fieldId: 'account[Bank_Account_Number__c]',
            
            name: 'account[Bank_Account_Number__c]',
            label: 'Account/IBAN Number',
            type: 'input',
            validations: {
              
              maxLength: {
                value: 250,
                message: 'This field cannot be more than 250 characters',
              },
            },
          },
          {
            fieldId: 'account[Bank_Account_IFSC__c]',
            name: 'account[Bank_Account_IFSC__c]',
            label: 'IFSC, in case of any Indian bank',
            type: 'input',
            validations: {
              maxLength: {
                value: 200,
                message: 'This field cannot be more than 200 characters',
              },
              pattern: {
                value: '',
                message: 'Please input a valid value',
              },
            },
          },
          {
            fieldId: 'account[Bank_Swift_Code__c]',
            name: 'account[Bank_Swift_Code__c]',
            label: 'Swift Code',
            type: 'input',
            validations: {
              pattern: {
                value: '',
                message: 'Please input a valid value',
              },
            },
          },
          {
            fieldId: 'account[Routing_Number__c]',
            name: 'account[Routing_Number__c]',
            label: 'Routing Number',
            validationType: 'number',
            validations: {
              pattern: {
                value: '',
                message: 'Please input a valid value',
              },
            },
          },
        ],
      },
    ],
  },
  {
    stepId: 'summaryWithDeclaration',
    stepName: 'Summary',
    stepPosition: 5,
    showStepName: true,
    stepVisible: true,
    stepType: 'summary',
    voucher: true,
    sections: [],
  },
];
