import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import KenUploadButton from './Components/KenUploadButton';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: 16,
  },
  cardContainer: {
    padding: 8,
  },
  rowWrap: {
    marginBottom: 30,
    // borderBottom: `1px solid ${theme.palette.KenColors.neutral60}`,
    // paddingBottom: 14,
  },
  rowWrapConditional: {
    // marginBottom: 0,
    borderBottom: 0,
  },
  rowLabel: {
    // color: theme.palette.KenColors.neutral900,
    // fontSize: 16,
    fontWeight: 500,
    color: '#091E42',
  },
  rowDesc: {
    // color: theme.palette.KenColors.neutral100,
    // fontSize: 14,
    maxWidth: '400px',
    color: '#7A869A',
  },
  mainContent: {
    position: 'relative',
  },
}));

export default function KenDocumentUpload(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    value = [],
    label,
    description,
    onChange,
    length,
    index,
    required,
    recordId,
    handleDownload,
    handleDelete,
    fileType,
    subHeader,
    topHeader,
  } = props;
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    multiple: false,
    noKeyboard: true,
    maxSize: 5000000,
    onDrop: (acceptedFiles, fileRejections) => {
      console.log(acceptedFiles, fileRejections);
      if (fileRejections.length > 0) {
        enqueueSnackbar(
          fileRejections[0]?.errors[0]?.message.replace('500000 bytes', '5 MB'),
          {
            variant: 'error',
          }
        );
      } else {
        if (
          acceptedFiles[0]?.type === 'application/pdf' ||
          acceptedFiles[0]?.type === 'image/jpeg' ||
          acceptedFiles[0]?.type === 'image/png'
        ) {
          onChange([...value, ...acceptedFiles]);
        } else {
          enqueueSnackbar('Upload PDF file', {
            variant: 'error',
          });
        }
      }
    },
  });

  const classes = useStyles();

  return (
    <div
      className={clsx(classes.rowWrap, {
        [classes.rowWrapConditional]: length === index,
      })}
    >
      {topHeader && (
        <>
        <Typography fontSize={17} mb={4} fontWeight={600}>
          {topHeader}
        </Typography>
        <Typography fontSize={17} mb={4} fontWeight={600}>
        {subHeader}
      </Typography>
      </>
      )}
      <Grid container {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Grid xs={12} md={7}>
          <Typography align="left" className={classes.rowLabel} variant="h5">
            {label}
            {required === true && <span style={{ color: '#E75547' }}>*</span>}
          </Typography>
          <Typography
            align="left"
            className={classes.rowDesc}
            variant="subtitle2"
          >
            {description}
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <KenUploadButton
            open={open}
            recordId={recordId}
            handleDownload={handleDownload}
            handleDelete={handleDelete}
            fileType={fileType}
            fileName={props?.fileName}
            {...props}
          />
        </Grid>
      </Grid>
    </div>
  );
}
