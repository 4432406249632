import React, { useContext, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation, useNavigate } from 'react-router-dom';

import { Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useApplicationContext } from '../../Context/applicationContext';
import { AuthContext } from '../../Context/authContext';
import { UploadDocumentContextProvider } from '../../Context/uploadDocumentContext';
import { defaultJson } from '../../Utils/Helpers/Forms/defaultTemplate';
import { Individual_JSON } from '../../Utils/Helpers/Forms/individual';
import { getApplicationDataByContactId } from '../../Utils/apiServices';
import KenApplicationFormHeader from './Components/DescriptionHeader';
import FormHeader from './Components/FormHeader';
import CustomizedSteppers from './Components/KenStepperHeader';
import Stepper from './Stepper';
import { Individual_JSON_Without_Questionnare } from '../../Utils/Helpers/Forms/individual_without_questionnare';
import { Entity_without_questionnare } from '../../Utils/Helpers/Forms/entity_without_questionnare';

export default function KenApplication() {
  const navigate = useNavigate();
  const formRef = useRef();
  const [loader, setLoader] = useState();
  const urlSearch = useLocation().search;

  const {
    state: { user },
  } = useContext(AuthContext);

  const { applicationState, applicationDispatch } = useApplicationContext();

  const {
    applicationId,
    applicationData,
    form,
    steps = [],
    activeStep,
    showHeader,
  } = applicationState;

  const [isDataFetched, setDataFetched] = useState();
  const { enqueueSnackbar } = useSnackbar();

  //update context state
  const updateState = (payload) => {
    applicationDispatch({ type: 'update', payload });
  };

  // TODO: Implement alert/toast
  const alertMessage = (message) => {
    console.log('alert:', message);
  };

  // update applicant id to context
  useEffect(() => {
    updateState({ applicantId: user?.applicantId });
  }, [user]);

  // get formid and applicationid from query
  useEffect(() => {
    console.log('KenAppli useEffect: urlSearch :', urlSearch);
    const query = new URLSearchParams(urlSearch);

    const tempId = query.get('formId');

    if (tempId && tempId !== 'null' && tempId !== 'undefined') {
      //update formId in context
      updateState({ formId: tempId });
    } else {
      // alert user that form is invalid
      alertMessage('Invalid formId. Try again');
      // redirect to dasboard
      // goToDashboard();
    }

    const id = query.get('applicationId');
    if (id) {
      updateState({ applicationId: id });
    }
  }, [urlSearch]);

  //actions to be performed if applicationId
  useEffect(() => {
    setLoader(true);
    if (!applicationId) {
      getApplicationDataByContactId(user?.applicantId).then((res) => {
        if (res?.success) {
          if (res?.data?.account?.Name?.includes('Account')) {
            delete res?.data?.account?.Name;
          }
          const USfund =
            res?.data?.funds?.findIndex((el) =>
              el?.Funding_Program__r?.Name?.includes('US Fund')
            ) > -1
              ? true
              : false;
          let steps =
            res?.data?.account?.Type === 'Individual'
              ? USfund
                ? Individual_JSON
                : Individual_JSON_Without_Questionnare
              : USfund
              ? defaultJson
              : Entity_without_questionnare;

          updateState({
            applicationData: res?.data,
            activeStep: 0,
            steps,
            totalSteps: steps.length,
          });
          setDataFetched(true);
        }

        setLoader(false);
      });
      return;
    }
  }, [applicationId, urlSearch]);

  // if formId or applicationId not valid redirect to dashboard

  return (
    <div>
      <FormHeader />
      {showHeader && (
        <>
          {form?.programName !== null ? (
            <KenApplicationFormHeader
              programName={form?.programName}
              duration={form?.courseDuration}
              batch={form?.courseBatch}
              endDate={form?.endDate}
              form={form?.formName}
            />
          ) : (
            <Skeleton
              height="8px"
              style={{
                marginTop: '70px',
                marginBottom: '20px',
                background: '#F6C445',
              }}
            />
          )}
        </>
      )}
      {/* Add condition when application data is fetched */}
      {/* Render instruction for first time when no application id found */}
      {/* {!applicationId &&
        applicationData === null &&
        !isDataFetched &&
        loader === false && <InstructionsStep />} */}
      {isDataFetched && (
        <>
          <CustomizedSteppers activeStep={activeStep} steps={steps} />
          <UploadDocumentContextProvider>
            <Stepper step={steps[activeStep]} formRef={formRef} />
          </UploadDocumentContextProvider>
        </>
      )}
      {(applicationId && !applicationData) ||
        (loader && (
          <Container maxWidth="md" style={{ textAlign: 'start' }}>
            <Skeleton
              width="40%"
              height="30px"
              style={{ marginTop: '50px', marginBottom: '20px' }}
            />
            <Skeleton
              count={2}
              inline
              width="45%"
              wrapper={({ children }) => (
                <span style={{ marginRight: '0.5rem' }}>{children}</span>
              )}
            />

            <Skeleton
              width="40%"
              height="30px"
              style={{ marginTop: '50px', marginBottom: '20px' }}
            />
            <Skeleton
              count={2}
              inline
              width="45%"
              wrapper={({ children }) => (
                <span style={{ marginRight: '0.5rem' }}>{children}</span>
              )}
            />
          </Container>
        ))}
    </div>
  );
}
