export const KEY_ACCESS_TOKEN = 'Access_Token';
export const KEY_CONFIG_URL = 'https://config-api.ken42.com/v1/configs/url';
export const REGEX = {
  NAME: /^[a-zA-Z ]*$/,
  MOBILE: /^(\+\d{1,3}[- ]?)?\d{10}$/,
  PINCODE: /^[0-9]{6,6}$/,
  NUMBER: /[^0-9]/,
  ONLYNUMBER: /^[0-9]*$/,
  NUMBERWITHDECIMAL: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
  DIGITS_ONLY_UPTO_100:
    /^\-?(([0-9]\d?|0\d{1,2})((\.)\d{0,2})?|100((\.)0{1,2})?)$/,
  DIGITS_ONLY_UPTO_10: /^\-?((0\d|\d)?((\.)\d{0,2})?|10((\.)0{1,2})?)$/,
  DIGITS_ONLY_UPTO_4:
    /^\-?((0[0-3]|[0-3])?((\.)\d{0,2})?|(4|04)((\.)0{1,2})?)$/,
  PERCENTAGE: /^100$/,
  CGPA: /^10$/,
  GRADE: /^[a-fA-FOoPp]$/,
  MIXED: /^[a-zA-Z0-9-./, ]*$/,
  MIXED_ADDRESS: /^[a-zA-Z0-9-.#/, ]*$/,
  PARENT_DOC: /^aadhaarParent/,
  PHONE_NUMBER: /^[0-9]*$/,
  AADHAAR: '^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$',
  URL: '^(https?:\\/\\/)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})([/\\w.-]*)*\\/?$',
  EMAIL:
    /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zzA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zzA-Z0-9](?:[a-zzA-Z0-9-]*[a-zzA-Z0-9])?\.)+[a-zzA-Z0-9](?:[a-zzA-Z0-9-]*[a-zzA-Z0-9])?/,
  //   EMAIL:
  //     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const ROLES = { APPLICANT: 'APPLICANT', PARTNER: 'PARTNER' };
export const PERMISSIONS = {
  [ROLES.APPLICANT]: {
    APP_HOME: 'APP_HOME',
    APP_APPLICATIONS: 'APP_APPLICATIONS',
    APP_DOCUMENTS: 'APP_DOCUMENTS',
    APP_THANKYOU: 'APP_THANKYOU',
    APP_FEEPAYEMENTS: 'APP_FEEPAYEMENTS',
    APP_COURSES: 'APP_COURSES',
    APP_ONBOARDING: 'APP_ONBOARDING',
    APP_FEEDETAILS: 'APP_FEEDETAILS',
    APP_OFFERLETTERDETAILED: 'APP_OFFERLETTERDETAILED',
    APP_INTERVIEWSLOTS: 'APP_INTERVIEWSLOTS',
    APP_INTERVIEW: 'APP_INTERVIEW',
    APP_OFFERLETTER: 'APP_OFFERLETTER',
    APP_FEESTRUCTURE: 'APP_FEESTRUCTURE',
    APP_HOME_NEW: 'APP_HOME_NEW',
    APP_EVENT: 'APP_EVENT',
  },
  [ROLES.PARTNER]: {
    PAR_HOME: 'PAR_HOME',
    PAR_APPLICATIONS: 'PAR_APPLICATIONS',
  },
};
export const TABLET_BREAKPOINT = 'tablet';
