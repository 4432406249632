import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography } from '@mui/material';
import Verify from '../common/Verify';
import { AuthContext } from '../../../Context/authContext';
import LoginBG from '../../../Assets/Svg/login-bg.svg';
import MobileLoginBG from '../../../Assets/Svg/login-mobile-bg.svg';
import { requestForOTP, verifyOTP } from '../api';
import { useNavigate, useLocation } from 'react-router-dom';
import routes from '../../../Constants/routes';
import validator from 'validator';
import KenLoader from '../../../Components/KenLoader';
import { useSnackbar } from 'notistack';
import { handleError } from '../util';
import LoginImage from '../../../Assets/Images/ts_investor.png';
import Logo from '../../../Assets/Images/turbostart_logo_white.png';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    // backgroundImage: `url(${LoginBG})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${MobileLoginBG})`,
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'flex-end',
    height: '100%',
    width: '50%',
    paddingBottom: 24,
    // paddingRight: '20%',
    backgroundImage: `url(${LoginImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('md')]: {
      backgroundImage: `url(${MobileLoginBG})`,
      display: 'none',
    },
    borderRadius: '0px 20px 20px 0px',
    // [theme.breakpoints.only('sm')]: {
    //   backgroundImage: `url(${MobileLoginBG})`,
    //   display: 'none',
    // },
  },
  heading: {
    fontSize: 48,
    fontWeight: 700,
    color: theme.palette.KenColors.kenWhite,
    paddingLeft: '24px',
    // position: 'absolute',
    // bottom: '15%',
    textTransform: 'capitalize',
    textAlign: 'left',
    // left: '1%',
    width: '96%',
  },
  subHeading: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.KenColors.kenWhite,
    // position: 'absolute',
    // bottom: '4%',
    // left: '1%',
    paddingLeft: 24,
    textAlign: 'left',
    width: '95%',
  },
  circularProgress: {
    position: 'absolute',
    top: '54%',
    left: '75%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('sm')]: {
      top: '50%',
      left: '49%',
    },
  },
  logo: {
    marginLeft: '30px',
    marginTop: '50px',
    marginBottom: '-40px',
    // marginRight: 'auto',
    // alignItems: 'left',
    // marginBottom: '-40px',
    height: '90px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
export default function LoginOTP(props) {
  const classes = useStyles();
  const { dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [isEmail, setIsEmail] = useState(null);
  const navigate = useNavigate();
  const { state: navigateState } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  console.log(navigateState);

  useEffect(() => {
    if (!navigateState?.id) {
      navigate(routes.login);
    } else {
      setIsEmail(validator.isEmail(navigateState.currentId));
    }
  }, [navigate, navigateState?.currentId, navigateState?.id]);

  const onSubmit = async ({ otp: { otp } }) => {
    setLoading(true);
    try {
      const res = await verifyOTP({
        otp,
        id: navigateState.currentId,
      });
      if (!res || !res?.success) {
        return enqueueSnackbar(res?.errorMessage || 'Something went wrong', {
          variant: 'error',
        });
      }

      // TODO: load token

      console.log('login check', res);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: navigateState.id,
            applicantEmail: res?.contactEmail,
            applicantId: res?.contactId,
            accountId: res?.accountId,
            applicantName: res?.contactName,
          },
          prog: navigateState?.prog,
        },
      });
    } catch (error) {
      handleError(enqueueSnackbar, error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid container direction="row" style={{ height: '100%', width: '100%' }}>
        <Grid
          container
          item
          xs={12}
          md={6}
          className={classes.imageContainer}
        ></Grid>
        <Grid item xs={12} md={6}>
          <>
            {typeof isEmail !== 'boolean' ? (
              <KenLoader />
            ) : (
              <Box className={classes.container}>
                <form>
                  <img
                    src={Logo}
                    onClick={() => {
                      window.open('https://www.turbostart.co/', '_self');
                    }}
                    className={classes.logo}
                  />
                  <Verify
                    loading={loading}
                    from="loginOTP"
                    title="Verify with OTP"
                    subTitle={
                      <>
                        <span>
                          {isEmail ? (
                            <div style={{ marginBottom: '-16px' }}>
                              <div
                                style={{
                                  fontSize: '12px',
                                  // marginBottom: '-8px',
                                }}
                              >
                                Almost there!
                              </div>
                              <div
                                style={{
                                  fontSize: '12px',
                                  lineHeight: '16px',
                                }}
                              >
                                Enter the OTP which was sent to your registered
                                email.
                              </div>
                              <div>
                                <p
                                  style={{
                                    marginTop: '8px',
                                    fontStyle: 'italic',
                                    fontSize: '10px',
                                  }}
                                >
                                  (Note: Don’t forget to check your spam
                                  folder!)
                                </p>
                              </div>
                            </div>
                          ) : (
                            'Please entre the OTP sent on your registered mobile number'
                          )}
                        </span>
                        {!isEmail && (
                          <strong style={{ marginLeft: '5px' }}>
                            *******{navigateState.currentId.slice(-3)}.
                          </strong>
                        )}
                      </>
                    }
                    submitText="Login"
                    onVerify={(otp) => {
                      onSubmit({ otp });
                    }}
                    resendOTP={async () => {
                      await requestForOTP({ id: navigateState.currentId });
                    }}
                  ></Verify>
                </form>
              </Box>
            )}
          </>
        </Grid>
      </Grid>
    </>
  );
}
