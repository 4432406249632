export const Individual_JSON = [
  {
    stepId: 'Company Details',
    stepName: 'Basic Details',
    showStepName: false,
    stepVisible: true,
    stepPosition: 0,
    stepType: 'form',
    sections: [
      {
        apiKeyId: 'application',
        sectionId: 'Investment Details',
        sectionName: 'Investment Details',
        subSectionName: '',
        sectionPosition: 1,
        sectionVisible: true,
        sectionHelp: 'Fields marked with * are required',
        sectionType: 'form',
        fields: [
          {
            fieldId: 'account[Type]',
            required: true,
            name: 'account[Type]',
            label: 'In what capacity will you be making your investment?',
            options: [
              {
                label: 'Individual',
                value: 'Individual',
              },
              {
                label: 'Legal Entity',
                value: 'Legal Entity',
              },
            ],
            type: 'investment',
            ui: {
              gridType: 'fullRow',
            },
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 200,
                message: 'This field cannot be more than 200 characters',
              },
            },
          },
        ],
      },
      {
        apiKeyId: 'application',
        sectionId: 'Basic Details',
        sectionName: 'Basic Details',
        subSectionName: '',
        sectionPosition: 1,
        sectionVisible: true,
        sectionHelp: 'Fields marked with * are required',
        sectionType: 'form',
        fields: [
          {
            fieldId: 'investors[0][FirstName]',
            required: true,
            name: 'investors[0][FirstName]',
            label: 'First Name',
            type: 'input',
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 200,
                message: 'This field cannot be more than 200 characters',
              },
            },
          },
          {
            fieldId: 'investors[0][LastName]',
            required: true,
            name: 'investors[0][LastName]',
            label: 'Last Name',
            type: 'input',
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 200,
                message: 'This field cannot be more than 200 characters',
              },
            },
          },

          {
            fieldId: 'investors[0][Email]',
            required: true,
            name: 'investors[0][Email]',
            label: 'Primary Email Address',
            type: 'input',
            validationType: 'email',
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 250,
                message: 'This field cannot be more than 250 characters',
              },
            },
          },
          {
            fieldId: 'investors[0][Secondary_Email_Address__c]',
            required: false,
            name: 'investors[0][Secondary_Email_Address__c]',
            label: 'Secondary Email Address',
            type: 'input',
            validationType: 'email',
          },
          {
            fieldId: 'account[Phone]',
            required: true,
            name: 'account[Phone]',
            label: 'Phone',
            type: 'phone',
            validations: {
              required: 'This field is required',
              pattern: {
                value: '',
                message: 'Please input a valid value',
              },
            },
          },
          {
            fieldId: 'investors[0][Nationality__c]',
            required: true,
            name: 'investors[0][Nationality__c]',
            label: 'Nationality',
            type: 'input',
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 200,
                message: 'This field cannot be more than 200 characters',
              },
              pattern: {
                value: '',
                message: 'Please input a valid value',
              },
            },
          },
          {
            fieldId: 'investors[0][Linkedin_Profile_URL__c]',
            name: 'investors[0][Linkedin_Profile_URL__c]',
            label: 'LinkedIn URL',
            type: 'input',
            validations: {
              maxLength: {
                value: 250,
                message: 'This field cannot be more than 250 characters',
              },
            },
          },
        ],
      },
      {
        apiKeyId: 'application',
        sectionId: 'Address',
        sectionName: 'Address',
        subSectionName: '',
        sectionPosition: 1,
        sectionVisible: true,
        sectionHelp: 'Fields marked with * are required',
        sectionType: 'form',
        fields: [
          {
            name: 'account',
            type: 'address',
            options: [
              {
                fieldId: 'BillingCountry',
                required: true,
                name: 'BillingCountry',
                label: 'Country',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
              },
              {
                fieldId: 'BillingState',
                required: true,
                name: 'BillingState',
                label: 'State',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
              },
              {
                fieldId: 'BillingCity',
                required: true,
                name: 'BillingCity',
                label: 'Town/City',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
              },
            ],
          },

          {
            fieldId: 'account[BillingStreet]',
            required: true,
            name: 'account[BillingStreet]',
            label: 'Address',
            type: 'input',
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 200,
                message: 'This field cannot be more than 200 characters',
              },
              pattern: {
                value: '',
                message: 'Please input a valid value',
              },
            },
          },
          {
            fieldId: 'account[BillingPostalCode]',
            required: true,
            name: 'account[BillingPostalCode]',
            label: 'Pin Code',
            type: 'input',
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 6,
                message: 'This field cannot be more than 6 characters',
              },
            },
          },
        ],
      },
    ],
  },
  {
    stepId: 'fundDetails',
    stepName: 'Fund Details',
    showStepName: false,
    stepVisible: true,
    stepPosition: 1,
    stepType: 'fundDetails',
    sections: [],
  },
  {
    stepId: 'additionalQuestions',
    stepName: 'KYC Documents',
    showStepName: false,
    stepVisible: true,
    stepPosition: 1,
    stepType: 'documentUpload',
    sections: [],
  },
  {
    stepId: 'Company Details',
    stepName: 'Bank Account Details',
    showStepName: false,
    stepVisible: true,
    stepPosition: 0,
    stepType: 'form',
    sections: [
      {
        apiKeyId: 'application',
        sectionId: 'Bank Account Details',
        sectionName: 'Bank Account Details',
        subSectionName: '',
        sectionPosition: 1,
        sectionVisible: true,
        sectionHelp: 'Fields marked with * are required',
        sectionType: 'form',
        fields: [
          {
            fieldId: 'account[Bank_Account_Holder_Name__c]',
            
            name: 'account[Bank_Account_Holder_Name__c]',
            label: 'Account Holder Name',
            type: 'input',
            validations: {
              
              maxLength: {
                value: 200,
                message: 'This field cannot be more than 200 characters',
              },
            },
            ui: {
              gridType: 'fullRow',
            },
          },

          {
            fieldId: 'account[Bank_Account_Number__c]',
            
            name: 'account[Bank_Account_Number__c]',
            label: 'Account/IBAN Number',
            type: 'input',
            validations: {
              
              maxLength: {
                value: 250,
                message: 'This field cannot be more than 250 characters',
              },
            },
          },
          {
            fieldId: 'account[Bank_Account_IFSC__c]',
            name: 'account[Bank_Account_IFSC__c]',
            label: 'IFSC, in case of any Indian bank',
            type: 'input',
            validations: {
              maxLength: {
                value: 200,
                message: 'This field cannot be more than 200 characters',
              },
              pattern: {
                value: '',
                message: 'Please input a valid value',
              },
            },
          },
          {
            fieldId: 'account[Bank_Swift_Code__c]',
            name: 'account[Bank_Swift_Code__c]',
            label: 'Swift Code',
            type: 'input',
            validations: {
              pattern: {
                value: '',
                message: 'Please input a valid value',
              },
            },
          },
          {
            fieldId: 'account[Routing_Number__c]',
            name: 'account[Routing_Number__c]',
            label: 'Routing Number',
            validationType: 'number',
            validations: {
              pattern: {
                value: '',
                message: 'Please input a valid value',
              },
            },
          },
        ],
      },
    ],
  },
  {
    stepId: 'Survey',
    stepName: 'Questionnare',
    showStepName: false,
    stepVisible: true,
    stepPosition: 1,
    stepType: 'survey',
    sections: [],
  },
  {
    stepId: 'summaryWithDeclaration',
    stepName: 'Summary',
    stepPosition: 5,
    showStepName: true,
    stepVisible: true,
    stepType: 'summary',
    voucher: true,
    sections: [],
  },
];
