import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import React from 'react';

import KenFieldContent from './Components/KenFieldContent';

const useStyles = makeStyles((theme) => ({
  sectionLabel: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

const SectionContent = ({ fields = [] }) => {
  return (
    <Grid container spacing={6} rowSpacing={4}>
      {fields.map((field) => (
        <KenFieldContent {...field} />
      ))}
    </Grid>
  );
};

export default function Section(props) {
  const { sectionName, subSectionName, sectionId, fields, isLast } = props;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();

  return (
    <>
      <Container maxWidth="md">
        {/* heading , responsiveness with accordions */}
        {isMobileScreen ? (
          //  accordion for mobile screens
          <>
            <Accordion
              square
              defaultExpanded
              sx={{
                border: 0,
                'box-shadow': 'none',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ padding: 0 }}
              >
                {sectionName && (
                  <Typography
                    align="left"
                    className={classes.sectionLabel}
                    fontSize="20px"
                  >
                    {sectionName}
                  </Typography>
                )}
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetail}>
                {subSectionName && (
                  <Typography
                    align="left"
                    marginBottom={theme.spacing(4)}
                    className={classes.sectionSubLabel}
                    variant="body2"
                  >
                    {subSectionName}
                  </Typography>
                )}
                <Box key={sectionId}>
                  <SectionContent fields={fields} />
                </Box>
              </AccordionDetails>
            </Accordion>
            <Divider />
          </>
        ) : (
          //
          <>
            {sectionName && (
              <Typography
                align="left"
                marginBottom={theme.spacing(4)}
                className={classes.sectionLabel}
                fontSize="20px"
              >
                {sectionName}
              </Typography>
            )}
            {subSectionName && (
              <Typography
                align="left"
                marginBottom={theme.spacing(4)}
                className={classes.sectionSubLabel}
                variant="body2"
              >
                {subSectionName}
              </Typography>
            )}
            <Box key={sectionId}>
              {/* <KenSectionContent {...props} /> */}
              <SectionContent fields={fields} />
            </Box>
            {!isLast && (
              <Divider
                style={{
                  margin: `${theme.spacing(3)} auto`,
                }}
              ></Divider>
            )}
          </>
        )}

        {/* fields to be displayed */}
      </Container>
    </>
  );
}
