const routes = {
  login: '/login',
  signup: '/signup',
  loginOTP: '/login-otp',
  verifyNumber: '/verify-number',
  verifyEmail: '/verify-email',
  verifiedOTP: '/otp-verified',
  newApplication: 'form',
  home: 'home',
  document: 'document',
  applications: 'applications',
  myApplications: 'my-applications',
  feePayments: 'feePayments',
  history: 'history',
  offerLetter: 'offerLetter',
  onboardQues: 'onboardQues',
  interviewSlots: 'interviewSlots',
  interview: 'interview',
  homepage: 'homepage',
};
export default routes;
