import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { createUpdateApplication } from '../../Utils/apiServices';
import KenInput from '../KenInput';
import KenTextLabel from '../KenTextLabel';
import KenStepperFooter from './Components/KenStepperFooter/index';
// import moment from 'moment';

const useStyles = makeStyles(() => ({
  title: {
    color: '#092682',
    fontWeight: 600,
    textAlign: 'left',
    fontSize: 20,
  },
  fundNotIncluded: {
    border: '1px solid #5C5C5C',
    borderRadius: 4,
    backgroundColor: '#fff',
    // cursor: 'pointer',
  },
  fundIncluded: {
    backgroundColor: '#5C5C5C',
    borderRadius: 4,
    // cursor: 'pointer',
  },
}));

const Fund_Type = [
  'Turbostart India Fund III',
  'Turbostart MEA Fund I',
  'Turbostart USA Fund I',
  'Turbostart Singapore Fund I',
];
const Fund_Amount = ['500000', '1000000', '1500000'];
const Number_of_installments = ['2', '3', '4'];

export default function FundDetails(props) {
  const styles = useStyles();
  const { applicationState, applicationDispatch } = props;
  const [fundType, setFundType] = useState([]);
  const [fundTypeData, setFundTypeData] = useState([]);
  const [totalFund, setTotalFund] = useState();

  useEffect(() => {
    if (applicationState?.applicationData?.fundProgram) {
      const funds = applicationState?.applicationData?.fundProgram;
      setFundType([
        ...funds?.map((el) => {
          return { id: el?.Id, name: el?.Name };
        }),
      ]);
      setTotalFund(
        '$' + applicationState?.applicationData?.account?.Total_Commitment__c
      );

      setFundTypeData([
        ...funds?.map((el) => {
          return {
            ...el,
            investedAmountArray: Fund_Amount,
            numberOfInstallmentArray: Number_of_installments,
            Amount_In_Words__c: el?.Amount_In_Words__c || '',
          };
        }),
      ]);
    }
  }, [applicationState]);

  // const {
  //   state: { user },
  // } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  console.log(applicationState);

  const handlePrev = () => {
    applicationDispatch({ type: 'prevStep' });
  };

  const handleNext = () => {
    applicationDispatch({ type: 'nextStep' });
    // if (fundType?.length <= 0) {
    //   return enqueueSnackbar('Select Funds', {
    //     variant: 'info',
    //   });
    // }
    // if (!totalFund) {
    //   // If there's no totalFund, proceed to the next step or take any other desired action
    //   applicationDispatch({ type: 'nextStep' }); // Proceed to the next step
    //   return; // Exit the function
    // }
    // let fundTypeString = '';
    // fundType.forEach((el, index) => {
    //   fundTypeString =
    //     fundTypeString + el + (index === fundType.length ? '' : ';');
    // });
    // let payload = { ...applicationState?.applicationData };
    // payload.account.Fund_Name__c = fundTypeString;
    // payload.account.Total_Fund_Amount__c = totalFund;
    // payload.funds = fundTypeData?.map((el, index) => {
    //   return {
    //     Id: applicationState?.applicationData?.funds?.[index]?.Id,
    //     Amount__c: el?.Amount__c,
    //     Instalments__c: '4',
    //     // Funding_Program__c:
    //     //   applicationState?.applicationData?.funds?.[index]?.Funding_Program__c,
    //     // Fund_Name__c: fundType[index],
    //     Account__c: payload?.account?.Id,
    //   };
    // });
    // createUpdateApplication(payload)
    //   .then((res) => {
    //     // dispatchAppContext({ type: 'udpateLoading', value: false });
    //     // on success update context data and active step
    //     if (res?.success) {
    //       // update context
    //       applicationDispatch({
    //         type: 'update',
    //         payload: { applicationData: res.data },
    //       });
    //       applicationDispatch({ type: 'nextStep' });
    //     }
    //   })
    //   .catch((err) => {
    //     // dispatchAppContext({ type: 'udpateLoading', value: false });
    //     //alert user of failure > try again
    //     console.log();
    //     enqueueSnackbar(
    //       err?.response?.data?.errorMessages[0]?.errorMessage ||
    //         'Something went wrong',
    //       {
    //         variant: 'info',
    //       }
    //     );
    //     console.log('Updating data failed. Try again');
    //   });
  };

  const handleChange = (event, index, name) => {
    const fundTypeDataCopy = [...fundTypeData];
    fundTypeDataCopy[index][name] = event.target.value;
    setFundTypeData(fundTypeDataCopy);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: '50vw',
          margin: '30px auto 0px auto',
          paddingBottom: '32px',
          marginBottom: '100px',
        }}
      >
        <Typography className={styles.title}>Fund Details</Typography>
        <Typography sx={{ color: 'black', fontSize: 12, marginBottom: '32px' }}>
          In case you may have any questions while filling out the form, please
          do feel free to reach out to finance@turbostart.co. One of us will
          reach out shortly.
        </Typography>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <KenTextLabel
              label={'Which fund are you investing in?'}
              required={true}
            />
            <Grid container item md={12} spacing={2}>
              {fundType &&
                fundType.map(({ id, name }) => {
                  const matchingFund =
                    applicationState.applicationData.funds.find(
                      (fund) => fund.Funding_Program__c === id
                    );

                  const isIncluded = !!matchingFund;

                  return (
                    <Grid item md={4} key={id}>
                      <Box
                        className={
                          isIncluded
                            ? styles.fundIncluded
                            : styles.fundNotIncluded
                        }
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: 14,
                            padding: '8px',
                            color: isIncluded ? 'white' : '#5C5C5C',
                          }}
                        >
                          {name}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
          {/* <Grid item md={6}>
            <KenInput
              label={'Total Commitment'}
              required={true}
              value={totalFund}
              disabled={true}
              onChange={(e) => {
                setTotalFund(e.target.value);
              }}
            />
          </Grid> */}
          {fundTypeData?.length > 0 &&
            fundTypeData
              .filter((el) =>
                applicationState?.applicationData?.funds?.some(
                  (fund) => fund.Funding_Program__c === el.Id
                )
              )
              .map((el, index) => {
                // Find the corresponding fund
                const matchingFund =
                  applicationState?.applicationData?.funds?.find(
                    (fund) => fund.Funding_Program__c === el.Id
                  );

                const dueDates =
                  applicationState?.applicationData?.fundProgram[index]
                    ?.Fund_Instalments__r?.records;

                return (
                  <Grid item md={12} key={el.Id}>
                    <Divider
                      sx={{
                        backgroundColor: '#E3E3E3',
                        height: '1px',
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: 18,
                        color: '#000000',
                        fontWeight: 500,
                        marginTop: '20px',
                        marginBottom: '20px',
                      }}
                    >
                      {el.Name}
                    </Typography>
                    <Grid container item md={12} spacing={2}>
                      <Grid item md={6}>
                        <KenInput
                          disabled={true}
                          label={'Proposed Investment'}
                          required={true}
                          value={`${matchingFund?.Currency_Type__c || ''} ${
                            matchingFund?.Amount__c || ''
                          }`}
                          onChange={(e) => handleChange(e, index, 'Amount__c')}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <KenInput
                          disabled={true}
                          label={'Proposed Investment in Words'}
                          value={matchingFund?.Amount_In_Words__c || ''}
                          onChange={(e) =>
                            handleChange(e, index, 'Amount_In_Words__c')
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item md={12}>
                      <Typography mt={2}>4 equal tranches of 25%</Typography>
                      <ul style={{ marginTop: '10px' }}>
                        {dueDates.map((dueDate, i) => (
                          <li key={i}>
                            Capital Drawdown {i + 1} - Due on or before{' '}
                            {moment(dueDate.Due_Date__c).format(
                              'Do MMMM, YYYY'
                            )}
                          </li>
                        ))}
                      </ul>
                    </Grid>
                  </Grid>
                );
              })}
        </Grid>
      </Box>

      <KenStepperFooter
        totalSteps={applicationState.totalSteps}
        activeStep={applicationState.activeStep}
        onPrev={handlePrev}
        onNext={handleNext}
        applicationStatus={
          applicationState?.applicationData?.application?.applicationStatus
        }
      />
    </React.Fragment>
  );
}
