import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginImage from '../../../Assets/Images/banner.png';
import LoginBG from '../../../Assets/Svg/login-bg.svg';
import MobileLoginBG from '../../../Assets/Svg/login-mobile-bg.svg';
import { AuthContext } from '../../../Context/authContext';
import {
  getSendOTPWithoutContact,
  postVerifyOTPWithoutContact,
} from '../../../Utils/apiServices';
import { signUp } from '../api';
import Verify from '../common/Verify';
import { handleError } from '../util';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundImage: `url(${LoginBG})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${MobileLoginBG})`,
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'flex-end',
    height: '100%',
    width: '50%',
    paddingBottom: 24,
    // paddingRight: '20%',
    backgroundImage: `url(${LoginImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('md')]: {
      backgroundImage: `url(${MobileLoginBG})`,
      display: 'none',
    },
    borderRadius: '0px 20px 20px 0px',
    // [theme.breakpoints.only('sm')]: {
    //   backgroundImage: `url(${MobileLoginBG})`,
    //   display: 'none',
    // },
  },
}));
export default function VerifyEmail() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const { state: navigateState } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  console.log(navigateState);

  // useEffect(() => {
  //   if (!navigateState?.id) {
  //     navigate(routes.signup);
  //   }
  // }, [navigate, navigateState?.id]);

  const onSubmit = async (otp) => {
    setLoading(true);
    try {
      const res = await postVerifyOTPWithoutContact(
        navigateState?.email,
        '',
        otp.otp
      );

      if (!res || !res?.success) {
        return enqueueSnackbar(res?.errorMessage || 'Something went wrong', {
          variant: 'error',
        });
      }

      if (res && res?.success) {
        const resp = await signUp(navigateState?.data);
        if (resp?.success) {
          dispatch({
            type: 'LOGIN',
            payload: {
              user: {
                id: resp.contactId,
                applicantEmail: resp?.contactEmail,
                applicantId: resp?.contactId,
                applicantName: resp?.contactName,
              },
            },
          });
        }
      }
    } catch (error) {
      handleError(enqueueSnackbar, error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container direction="row" style={{ height: '100%', width: '100%' }}>
      <Grid
        container
        item
        xs={12}
        md={6}
        className={classes.imageContainer}
      ></Grid>
      <Grid item xs={12} md={6}>
        <>
          <Box className={classes.container}>
            <form>
              <Verify
                loading={loading}
                from="verifyEmail"
                title="Verify Your Email ID"
                subTitle={
                  <>
                    <span>
                      Please enter the OTP sent to your registered Email ID.
                    </span>
                  </>
                }
                submitText="Verify & Sign Up"
                onVerify={(otp) => {
                  onSubmit(otp);
                }}
                resendOTP={async () => {
                  let res = await getSendOTPWithoutContact(
                    navigateState?.data?.email,
                    navigateState?.data?.phone,
                    navigateState?.data?.firstName +
                      ' ' +
                      navigateState?.data?.lastName
                  );
                  if (res?.success) {
                    enqueueSnackbar('The OTP has been sent to your Email Id', {
                      variant: 'success',
                    });
                  }
                }}
              ></Verify>
            </form>
          </Box>
        </>
      </Grid>
    </Grid>
  );
}
