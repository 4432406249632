import { Box, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  createDocuments,
  deleteDocument,
  getDocumentByDocId,
} from '../../Utils/apiServices';
import { makeStyles } from '@mui/styles';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import KenDocumentUpload from '../KenDocumentUpload';
import KenStepperFooter from './Components/KenStepperFooter/index';
import KenLoader from '../KenLoader';

const useStyles = makeStyles(() => ({
  title: {
    color: '#092682',
    fontWeight: 600,
    textAlign: 'left',
    marginBottom: 32,
    fontSize: 20,
  },
}));

export default function DocumentUpload(props) {
  // console.log()
  const styles = useStyles();
  const { applicationState, applicationDispatch } = props;
  // const [appState, setAppState] = useState([applicationState]);
  const [url, setUrl] = useState();
  const [filePreview, setFilePreview] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [file, setFile] = useState();
  const [flag, setFlag] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [uploadData, setUploadData] = useState([
    {
      title:
        'Certificate of Registration (The certificate should be attested by the authorized signatory of the entity)',
      description: 'jpeg, pdf, png formats supported ',
      previewImage: null,
      required: true,
    },
    {
      title: 'PAN Card',
      description: 'jpeg, pdf, png formats supported ',
      previewImage: null,
      required: false,
    },
    {
      title: 'Resolution Passed by the Partners/Board/Management',
      description: 'jpeg, pdf, png formats supported ',
      previewImage: null,
      required: true,
    },
  ]);

  const USfund =
    applicationState?.applicationData?.funds?.findIndex((el) =>
      el?.Funding_Program__r?.Name?.includes('US Fund')
    ) > -1
      ? true
      : false;

  useEffect(() => {
    if (applicationState?.applicationData?.account?.Type === 'Individual') {
      let document = [
        'Self-attested copy of PAN Card (if available)',
        'Self-attested copy of Aadhaar, if available OR Self-attested copy of Passport',
        'Proof of Address',
      ];
  
      if (USfund) {
        document.push('Form W9');
      }
  
      // Define unique descriptions for each title
      const descriptions = {
        'Aadhaar/ Passport': (
          <>
            <ul>
              <li>Self-attested copy of Aadhaar, if available; OR</li>
              <li>Self-attested copy of Passport.</li>
            </ul>
            <div>jpeg, pdf, png formats supported</div>
          </>
        ),
        'Proof of Address': (
          <>
            <ul>
              <li>
                Proof of address (not applicable if passport is provided as
                proof of identity and address is mentioned therein):
              </li>
              <li>
                Self-attested copy of utility bill (mobile, landline,
                electricity, gas etc. not older than a month); OR
              </li>
              <li>
                Self-attested copy of bank statement (transactions shall be
                masked).
              </li>
            </ul>
            <div>jpeg, pdf, png formats supported</div>
          </>
        ),
      };
  
      setUploadData(
        document.map((el) => {
          let index = applicationState?.applicationData?.attachments?.findIndex(
            (elem) => elem.title === el
          );
          if (index !== -1) {
            let payload = {
              ...applicationState?.applicationData?.attachments[index],
              title: el,
              description: descriptions[el], // Use the unique description based on the title
              previewImage: null,
              required: true,
            };
            if (el === 'Self-attested copy of PAN Card (if available)') {
              payload.topHeader = 'Proof of identity';
              payload.required = false;
            }
            if (el === 'Form W9') {
              // Add a conditional check for 'Individual' type
              if (applicationState?.applicationData?.account?.Type === 'Individual') {
                payload.templateDownload = true;
                payload.templateURL = 'https://inazstgpfs3001.blob.core.windows.net/assets/Form%20W9.pdf';
              } else {
                payload.templateDownload = false;
                payload.templateURL = null;
              }
            }
            return payload;
          } else {
            let payload = {
              title: el,
              description: 'jpeg, pdf, png formats supported',
              previewImage: null,
              required: true,
            };
            if (el === 'Self-attested copy of PAN Card (if available)') {
              payload.topHeader = 'Proof of identity';
              payload.required = false;
            }
            if (el === 'Form W9') {
              // Add a conditional check for 'Individual' type
              if (applicationState?.applicationData?.account?.Type === 'Individual') {
                payload.templateDownload = true;
                payload.templateURL = 'https://inazstgpfs3001.blob.core.windows.net/assets/Form%20W9.pdf';
              } else {
                payload.templateDownload = false;
                payload.templateURL = null;
              }
            }
            return payload;
          }
        })
      );
    } else if (
      applicationState?.applicationData?.account?.Type === 'Legal Entity'
    ) {
      let document = [
        'Copy of the certificate of registration attested by the authorised signatory of the entity',
        'Copy of PAN Card, if available',
        'Copy of resolution passed by the Partners/ Board/ Management of the entity approving the investment in the respective Turbostart Fund and authorizing the authorized signatory to do all necessary acts on behalf of the entity.',
        'Entity Structure',
        'Self-attested copy of PAN Card (if available)',
        'Self-attested copy of Aadhaar, if available OR Self-attested copy of Passport',
        'Proof of Address',
        'Others',
      ];
      if (USfund) {
        document.splice(4, 0, 'Form W9');
      }
      const descriptions = {
        'Self-attested copy of Aadhaar Card': (
          <>
            <ul>
              <li>Self-attested copy of Aadhaar, if available; OR</li>
              <li>Self-attested copy of Passport.</li>
            </ul>
            <div>jpeg, pdf, png formats supported</div>
          </>
        ),
        'Proof of Address': (
          <>
            <ul>
              <li>
                Proof of address (not applicable if passport is provided as
                proof of identity and address is mentioned therein):
              </li>
              <li>
                Self-attested copy of utility bill (mobile, landline,
                electricity, gas etc. not older than a month); OR
              </li>
              <li>
                Self-attested copy of bank statement (transactions shall be
                masked).
              </li>
            </ul>
            <div>jpeg, pdf, png formats supported</div>
          </>
        ),
      };

      setUploadData(
        document.map((el, idx) => {
          let index = applicationState?.applicationData?.attachments?.findIndex(
            (elem) => {
              return elem.title === el;
            }
          );

          if (index !== -1) {
            let payload = {
              ...applicationState?.applicationData?.attachments[index],
              title: el,
              description:
                descriptions[el] || 'jpeg, pdf, png formats supported ',
              previewImage: null,
              required:
                el === 'Copy of PAN Card, if available' || el === 'Others'
                  ? false
                  : true,
            };
            if (el === 'Self-attested copy of PAN Card (if available)') {
              payload.topHeader =
                'Below documents with respect to the authorized signatory Proof of Identity';
              payload.subHeader = 'Proof of Identity';
              payload.required = false;
            }
            if (idx === 2) {
              payload.templateDownload = true;
              payload.templateURL =
                'https://inazstgpfs3001.blob.core.windows.net/assets/Turbostart/Resolution%20Template.docx';
            }
            if (idx === 3) {
              payload.templateDownload = true;
              payload.templateURL =
                'https://inazstgpfs3001.blob.core.windows.net/assets/Turbostart/UBO%20Organization%20Chart.pdf';
            }
            if (el === 'Form W9') {
              payload.templateDownload = true;
              payload.templateURL =
                'https://inazstgpfs3001.blob.core.windows.net/assets/Form%20W9.pdf';
            }
            return payload;
          } else {
            let payload = {
              title: el,
              description: 'jpeg, pdf, png formats supported ',
              previewImage: null,
              required:
                el === 'Copy of PAN Card, if available' || el === 'Others'
                  ? false
                  : true,
            };
            if (el === 'Self-attested copy of PAN Card (if available)') {
              payload.topHeader =
                'Below documents with respect to the authorized signatory ';
              payload.subHeader = 'Proof of Identity';
              payload.required = false;
            }
            if (idx === 2) {
              payload.templateDownload = true;
              payload.templateURL =
                'https://inazstgpfs3001.blob.core.windows.net/assets/Turbostart/Resolution%20Template.docx';
            }
            if (idx === 3) {
              payload.templateDownload = true;
              payload.templateURL =
                'https://inazstgpfs3001.blob.core.windows.net/assets/Turbostart/UBO%20Organization%20Chart.pdf';
            }
            if (el === 'Form W9') {
              payload.templateDownload = true;
              payload.templateURL =
                'https://inazstgpfs3001.blob.core.windows.net/assets/Form%20W9.pdf';
            }
            return payload;
          }
        })
      );

      // Add "Document Name - Form W9" based on the condition of USfund
      // if (USfund) {
      //   // Common code for both 'Individual' and 'Legal Entity'
      //   const documentNameFormW9 = {
      //     title: 'Document Name - Form W9',
      //     description: 'jpeg, pdf, png formats supported',
      //     previewImage: null,
      //     required: true,
      //   };

      //   // Additional properties specific to 'documentNameFormW9'
      //   if (
      //     applicationState?.applicationData?.account?.Type === 'Legal Entity'
      //   ) {
      //     documentNameFormW9.templateDownload = true;
      //     documentNameFormW9.templateURL =
      //       'https://inazstgpfs3001.blob.core.windows.net/assets/Form%20W9.pdf';
      //   }

      //   setUploadData((prevUploadData) => {
      //     const indexEntityStructure = prevUploadData.findIndex(
      //       (el) => el.title === 'Entity Structure'
      //     );

      //     if (indexEntityStructure > -1) {
      //       return [
      //         ...prevUploadData.slice(0, indexEntityStructure + 1),
      //         documentNameFormW9,
      //         ...prevUploadData.slice(indexEntityStructure + 1),
      //       ];
      //     }

      //     // If 'Entity Structure' is not found, just add 'Document Name - Form W9' at the end
      //     return [...prevUploadData, documentNameFormW9];
      //   });
      // }
    }
  }, [applicationState, flag, USfund]);

  useEffect(() => {
    if (applicationState?.application?.attachments) {
      const uploadDataCopy = [...uploadData];
      applicationState?.application?.attachments?.forEach((el) => {
        let index = uploadDataCopy.findIndex((elem) => elem.title === el.title);
        if (index > -1) {
          uploadDataCopy[index] = { ...uploadDataCopy[index], ...el };
        }
      });
      setUploadData([...uploadDataCopy]);
      setPageLoader(false);
    }
  }, []);

  const convertBase64ToFile = (base64String, fileName, fileType) => {
    // let arr = base64String.split(',');
    let mime = fileType;
    let bstr = atob(base64String);
    let n = bstr.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    let file = new File([uint8Array], fileName, { type: fileType });
    return file;
  };

  useEffect(() => {
    if (!(file instanceof File)) {
      setUrl(file);
    }
  }, [file]);

  const onError = (err) => console.log('Error:', err);

  const handlePrev = () => {
    applicationDispatch({ type: 'prevStep' });
  };
  const handleNext = () => {
    let flagFalse = 0;
    uploadData.forEach((el) => {
      if (el.required === true) {
        if (!el.contentDocumentId) {
          flagFalse++;
        }
      }
    });
    if (flagFalse === 0) {
      applicationDispatch({ type: 'nextStep' });
    } else {
      return enqueueSnackbar('Upload all documents', {
        variant: 'info',
      });
    }
  };

  const handleDownload = (id, fileType, fileName) => {
    setPageLoader(true);
    getDocumentByDocId(id)
      .then((res) => {
        if (res?.success && res?.data) {
          setPageLoader(false);
          let file = convertBase64ToFile(res?.data, fileName, fileType);
          saveAs(file, fileName);
        }
      })
      .catch((err) => {
        console.log(err);
        setPageLoader(false);
      });
  };

  const handleDelete = (id) => {
    setPageLoader(true);
    deleteDocument(id)
      .then((res) => {
        if (res?.success) {
          setPageLoader(false);
          enqueueSnackbar('Document deleted successfully', {
            variant: 'success',
          });
          const uploadDataCopy = [...uploadData];
          let index = uploadDataCopy.findIndex(
            (el) => el.contentDocumentId === id
          );
          let template = false;
          if (uploadDataCopy[index].templateDownload === true) {
            template = true;
          }
          if (index > -1) {
            uploadDataCopy[index] = {
              description: uploadDataCopy[index].description,
              previewImage: uploadDataCopy[index].previewImage,
              required: uploadDataCopy[index].required,
              title: uploadDataCopy[index].title,
              topHeader: uploadDataCopy[index].topHeader,
              templateDownload:
                template === true
                  ? uploadDataCopy[index].templateDownload
                  : false,
            };
          }
          console.log(uploadDataCopy);
          setUploadData([...uploadDataCopy]);
        } else {
          console.error('Delete failed:', res?.error);
          setPageLoader(false);

          enqueueSnackbar('Failed to delete document. Please try again.', {
            variant: 'error',
          });
        }
      })
      .catch((err) => {
        console.error('Error during delete:', err);
        setPageLoader(false);
        enqueueSnackbar(
          'An error occurred during deletion. Please try again.',
          {
            variant: 'error',
          }
        );
      });
  };
  const uploadDoc = (file, el) => {
    debugger;
    setPageLoader(true);
    const reader = new FileReader();
    let base64;
    reader.onloadend = () => {
      base64 = reader.result.replace('data:', '').replace(/^.+,/, '');
      const body = {
        fileName: file?.name,
        title: el.title,
        expiryDate: null,
        body: base64,
        accountId: applicationState?.applicationData?.account?.Id,
        fileType: file.type,
      };
      createDocuments({ attachments: [body] })
        .then((res) => {
          setPageLoader(false);
          if (res?.success === true) {
            // getData();
            applicationDispatch({
              type: 'update',
              payload: {
                applicationData: res?.data,
              },
            });
            return enqueueSnackbar('Document Uploaded Successfully', {
              variant: 'success',
            });
          }
        })
        .catch((err) => {
          setPageLoader(false);
          console.log('error', err);
          return enqueueSnackbar(
            'Error while uploading. Please try again later.',
            {
              variant: 'error',
            }
          );
        });
    };
    reader.readAsDataURL(file);
  };

  return (
    <React.Fragment>
      {pageLoader && <KenLoader />}

      <Box
        sx={{
          width: '60vw',
          margin: '30px auto 0px auto',
          paddingBottom: '32px',
        }}
      >
        <Typography className={styles.title}>
          Document Upload (Max File Size: 5MB)
          <span style={{ fontSize: 12, marginLeft: '8px' }}>
            {/* (Max File Size - 5 MB) */}
          </span>
        </Typography>
        {uploadData?.map((el) => {
          return (
            <>
              <KenDocumentUpload
                label={el.title}
                documentID={el.contentDocumentId}
                // previewImage={el.previewImage}
                // removeFile={(el)=>handleFileDelete(el)}
                handleDownload={handleDownload}
                handleDelete={handleDelete}
                onChange={(file) => uploadDoc(file[0], el)}
                {...el}
              />

              <Divider
                variant="fullWidth"
                style={{ background: '#E2E2E2', marginBottom: '30px' }}
              />
            </>
          );
        })}
      </Box>

      <KenStepperFooter
        totalSteps={applicationState.totalSteps}
        activeStep={applicationState.activeStep}
        onPrev={handlePrev}
        onNext={handleNext}
        applicationStatus={
          applicationState?.applicationData?.application?.applicationStatus
        }
      />
    </React.Fragment>
  );
}
