import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AuthContext } from '../../Context/authContext';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../Context/appContext';
import { useApplicationContext } from '../../Context/applicationContext';
import {
  addExtrasFieldsToObject,
  filterDirtyFields,
  getDirtyValues,
} from '../../Utils/Helpers/applicationHelper';
import { createUpdateApplication } from '../../Utils/apiServices';
import KenStepperFooter from './Components/KenStepperFooter';
import Section from './Section';

function Form({ step: { sections = [] } = {} }, ref) {
  const {
    applicationState: {
      applicationData,
      applicationId,
      activeStep,
      applicantId,
      totalSteps,
    } = {},
    applicationDispatch,
  } = useApplicationContext();
  const { dispatch: dispatchAppContext } = useAppContext();
  const {
    state: { user },
  } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  // React hook form to maintain form state

  const methods = useForm({
    defaultValues: applicationData,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const {
    formState: { errors, dirtyFields },
    getValues,
    reset,
    setValue,
  } = methods;

  // reset form on change of activeStep
  useEffect(() => {
    resetForm();
    window.scrollTo(0, 0);
  }, [activeStep]);

  // Reset form
  const resetForm = (data = applicationData) => {
    reset(data, {
      keepDirty: false,
      keepErrors: false,
      keepDirtyValues: false,
      keepTouched: false,
      keepValues: false,
    });
  };

  // Next handler
  const handleNext = () => {
    ref?.current?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    );
  };

  const handlePrev = () => {
    applicationDispatch({ type: 'prevStep' });
  };

  const handleSave = () => {
    if (!_.isEmpty(errors)) {
      //alert "Please resolve all errors"
      console.log('Please resolve all errors');
      enqueueSnackbar(t('messages:Application_Errors_Found'), {
        variant: 'error',
      });
      return;
    }

    const changedValues = getDirtyValues(
      filterDirtyFields(dirtyFields),
      getValues()
    );

    // if no dirtyValues show alert no changes found
    if (_.isEmpty(changedValues)) {
      console.log('No Changes found');
      enqueueSnackbar(t('messages:Application_No_Changes'), {
        variant: 'info',
      });
      return;
    }

    const payload = addExtrasFieldsToObject(changedValues, {
      Applicant__c: applicantId,

      // applicationId,
      // parentId: applicantId,
    });

    dispatchAppContext({ type: 'udpateLoading', value: true });
    createUpdateApplication({
      applicationId,
      ...payload,
      accountId: user?.accountId,
    })
      .then((res) => {
        // on success update context data and active step
        if (res?.success) {
          console.log('Saved data successfully');
          enqueueSnackbar(t('messages:Application_Save_Success'), {
            variant: 'success',
          });
          applicationDispatch({
            type: 'update',
            payload: { applicationData: res.data },
          });
          dispatchAppContext({ type: 'udpateLoading', value: false });
          resetForm(res.data);
        }
      })
      .catch((err) => {
        //alert user of failure > try again
        dispatchAppContext({ type: 'udpateLoading', value: false });
        console.log('Updating data failed. Try again');
        enqueueSnackbar(t('messages:Application_Save_Failure'), {
          variant: 'error',
        });
      });
    // applicationDispatch({ type: 'nextStep' });
  };

  const onSubmit = (formValues) => {
    if (!_.isEmpty(errors)) {
      //alert "Please resolve all errors"
      console.log('Please resolve all errors');
      enqueueSnackbar(t('messages:Application_Errors_Found'), {
        variant: 'error',
      });
      return;
    }

    const USfund =
      getValues().funds?.findIndex((el) =>
        el?.Funding_Program__r?.Name?.includes('US Fund')
      ) > -1
        ? true
        : false;

    // Get values of only dirtyFields

    let changedValues = getDirtyValues(
      filterDirtyFields(dirtyFields),
      getValues()
    );

    // if no dirtyValues show alert no changes found
    if (_.isEmpty(changedValues)) {
      console.log('No Changes found');
      applicationDispatch({ type: 'nextStep' });
      return;
    }

    const payload = addExtrasFieldsToObject(changedValues, {
      applicationId,
    });
    // SetLoader
    dispatchAppContext({ type: 'udpateLoading', value: true });
    // update currentStep
    createUpdateApplication({
      applicationId,
      accountId: user?.accountId,
      ...payload,
    })
      .then((res) => {
        dispatchAppContext({ type: 'udpateLoading', value: false });
        // on success update context data and active step
        if (res?.success) {
          // update context
          applicationDispatch({
            type: 'update',
            payload: { applicationData: res.data },
          });

          applicationDispatch({ type: 'nextStep' });
        }
      })
      .catch((err) => {
        dispatchAppContext({ type: 'udpateLoading', value: false });
        //alert user of failure > try again
        enqueueSnackbar(
          err?.response?.data?.errorMessages[0]?.errorMessage ||
            t('messages:Application_Errors_Found'),
          {
            variant: 'info',
          }
        );
        console.log('Updating data failed. Try again');
      });
    // applicationDispatch({ type: 'nextStep' });
  };

  return (
    <div>
      <form
        ref={ref}
        onSubmit={methods.handleSubmit(onSubmit)}
        style={{ marginBottom: 120 }}
      >
        <FormProvider {...methods}>
          {sections.map((section, index) => (
            <Section
              {...section}
              key={index}
              isLast={index === sections.length - 1}
            />
          ))}
        </FormProvider>
      </form>
      {/* // Footer stepper is consistent   */}
      {/* <Footer /> */}
      <KenStepperFooter
        onNext={handleNext}
        onPrev={handlePrev}
        onSave={handleSave}
        activeStep={activeStep}
        totalSteps={totalSteps}
      />
    </div>
  );
}

export default React.forwardRef(Form);
