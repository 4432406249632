import { Box, CircularProgress, Grid, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import validator from 'validator';
import Logo from '../../../Assets/Images/turbostart_logo_white.png';
import MobileLoginBG from '../../../Assets/Svg/login-mobile-bg.svg';
import KenButton from '../../../Components/KenButton';
import KenInput from '../../../Components/KenInput';
import routes from '../../../Constants/routes';
import { requestForOTP } from '../api';
import { SubTitle, Title } from '../common';
import { handleError } from '../util';
import { useTheme } from '@emotion/react';
import LoginImage from '../../../Assets/Images/ts_investor.png';
import KenLoader from '../../../Components/KenLoader';
import { AuthContext } from '../../../Context/authContext';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    // paddingRight: '20%',
    // backgroundImage: `url(${LoginImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      // backgroundImage: `url(${MobileLoginBG})`,
    },
  },
  loginContent: {
    minWidth: '500px',
    minHeight: '520px',
    'box-shadow':
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    'border-radius': '16px',
    padding: '48px 84px',
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      padding: '8px',
      minWidth: '360px',
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'flex-end',
    height: '100%',
    width: '50%',
    paddingBottom: 24,
    // paddingRight: '20%',
    backgroundImage: `url(${LoginImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('md')]: {
      backgroundImage: `url(${MobileLoginBG})`,
      display: 'none',
    },
    borderRadius: '0px 20px 20px 0px  ',
  },
  title: {
    marginBottom: '16px',
  },
  subTitle: {
    marginBottom: '32px',
  },
  inputFieldLabel: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '100%',
    'text-transform': 'capitalize',
    color: '#505F79',
  },
  input: {
    borderRadius: 10,
    color: 'yellow',
    fontSize: 20,
    background: 'pink',
  },
  verify: {
    width: '100%',
    height: '48px',
    marginBottom: '40px',
    fontSize: '16px',
    fontWeight: 600,
    backgroundColor: '#092682',
    fontFamily: 'Poppins,sans-serif',
  },
  signupWith: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '100%',
    color: theme.palette.KenColors.neutral100,
  },
  signupWithItem: {
    margin: '0 8px',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    '& img': {
      height: '24px',
      width: '24px',
    },
    '& img.google': {
      width: '37px',
      height: '40px',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  heading: {
    fontSize: 48,
    fontWeight: 700,
    color: theme.palette.KenColors.kenWhite,
    paddingLeft: '24px',
    // position: 'absolute',
    // bottom: '15%',
    textTransform: 'capitalize',
    textAlign: 'left',
    // left: '1%',
    width: '96%',
  },
  subHeading: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.KenColors.kenWhite,
    // position: 'absolute',
    // bottom: '4%',
    // left: '1%',
    paddingLeft: 24,
    textAlign: 'left',
    width: '95%',
  },
  circularProgress: {
    position: 'absolute',
    top: '54%',
    left: '75%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('sm')]: {
      top: '50%',
      left: '49%',
    },
  },
  logo: {
    marginLeft: '-50px',
    // marginRight: 'auto',
    alignItems: 'left',
    // marginBottom: '-40px',
    height: '90px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const mobileOrEmail = (id) => {
  //Note strict mode will enable only validation with country code
  if (
    validator.isEmail(id) ||
    validator.isMobilePhone(id, 'en-IN', { strictMode: true })
  ) {
    return true;
  }

  return 'Please enter valid email.';
};

export default function Login() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useContext(AuthContext);
  const theme = useTheme();
  const screenSizeSm = useMediaQuery(theme.breakpoints.down('sm'));

  const query = new URLSearchParams(useLocation().search);
  const prog = query.get('prog');

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await requestForOTP(data);

      if (!res || !res?.success) {
        console.log(res?.errorMessage);
        return enqueueSnackbar(res?.errorMessage || 'Something went wrong', {
          variant: 'error',
        });
      }

      navigate(routes.loginOTP, {
        state: {
          id: res.contactId,
          currentId: data.id,
          prog: prog ? prog : '',
        },
      });
    } catch (error) {
      handleError(enqueueSnackbar, error);
    } finally {
      setLoading(false);
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: '',
    },
  });

  return (
    <Grid container direction="row" style={{ height: '100%', width: '100%' }}>
      <Grid
        container
        item
        xs={12}
        md={6}
        className={classes.imageContainer}
      ></Grid>
      <Grid item xs={12} md={6}>
        <Box className={classes.container}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {pageLoader && <KenLoader />}
            <Grid
              container
              direction={'column'}
              alignItems={'flex-start'}
              justifyContent={'left'}
            >
              <Grid item>
                <img
                  src={Logo}
                  onClick={() => {
                    window.open('https://www.turbostart.co/', '_self');
                  }}
                  className={classes.logo}
                />
              </Grid>
              <Grid item>
                <Box
                  // className={classes.loginContent}
                  display={'flex'}
                  flexDirection="column"
                  justifyContent={'flex-start'}
                  alignItems="flex-start"
                >
                  <Title className={classes.title} text="Welcome" />
                  <SubTitle
                    className={classes.subTitle}
                    text="Hey, Investor! Welcome to the Turbostart Investor’s Portal."
                  />
                  <Box mb={4} width="100%">
                    <Controller
                      name="id"
                      control={control}
                      rules={{ required: true, validate: { mobileOrEmail } }}
                      render={({ field, fieldState: { error } }) => (
                        <KenInput
                          // required
                          hideLabel={true}
                          name="id"
                          label="Email Id"
                          placeholder="Enter your preferred Email ID to login"
                          labelClassName={classes.inputFieldLabel}
                          error={error?.message || error?.type}
                          inputType="circular"
                          inputProps={classes.input}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                  <KenButton
                    variant="roundedButton"
                    buttonClass={classes.verify}
                    type="submit"
                    disabled={loading}
                  >
                    Verify Email
                  </KenButton>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.circularProgress}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
}
