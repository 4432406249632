import axios from 'axios';
import { KEY_ACCESS_TOKEN, KEY_CONFIG_URL } from '../Constants/constant';

let appConfig;
export let axiosInstance;
const configUrl = KEY_CONFIG_URL;

export const getAxiosInstance = (data) => {
  if (axiosInstance) {
    return axiosInstance;
  }
  appConfig = data[0]?.config;
  axiosInstance = axios.create({
    baseURL: `${appConfig.apiBaseUrl}`,
    timeout: 15000,
  });

  axiosInstance.interceptors.request.use((req) => {
    const token = localStorage.getItem(KEY_ACCESS_TOKEN);
    req.headers.Authorization = `Bearer ${token}`;
    return req;
  });

  //Response interceptor for API calls
  axiosInstance.interceptors.response.use(
    (resp) => {
      return resp;
    },
    async function (error) {
      console.log('ApiService: Interceptor: ', error);
      if (!error.response?.config && error.response?.status !== 401) {
        return error;
      }
      var config = {
        method: 'get',
        baseURL: `${appConfig.tokenUrl}`,
      };
      return axios(config).then(function (response) {
        localStorage.setItem(KEY_ACCESS_TOKEN, response.data.access_token);
        error.response.config.headers[
          'Authorization'
        ] = `Bearer ${response.data.access_token}`;
        return axios(error.response.config);
      });
    }
  );
};

export const getConfig = async () => {
  // const path = `${window.location.host}`;
  const path = 'investor.turbostart.co';
  const res = await axios.get(`${configUrl}/${path}`);
  return res && res.data ? res.data : null;
};

export const getApplicationByApplicationId = async (applicationId) => {
  const path = `salesforce?endpoint=/services/apexrest/investor/retrieve/application?applicationid=${applicationId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const createUpdateApplication = async (data) => {
  const path = `salesforce?endpoint=/services/apexrest/investor/save`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const deleteApplicationRecords = async (data) => {
  const delData = { data: data };
  const path = `salesforce?endpoint=/services/apexrest/investor/delete`;
  const res = await axiosInstance.delete(`${path}`, delData);
  return res && res.data ? res.data : null;
};

export const getDocumentByDocId = async (docId) => {
  const path = `salesforce?endpoint=services/apexrest/investor/retrieve/document?docid=${docId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const createDocuments = async (data) => {
  const path = `salesforce?endpoint=/services/apexrest/investor/save`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const getSendOTPWithoutContact = async (email, phone, name) => {
  const path = `salesforce?endpoint=${encodeURIComponent(
    `services/apexrest/signupotp/send?email=${email}&phone=${phone}&name=${name}`
  )}`;
  const res = await axiosInstance.post(`${path}`);
  return res && res.data ? res.data : null;
};

export const postVerifyOTPWithoutContact = async (email, smsOtp, emailOtp) => {
  const path = `salesforce?endpoint=${encodeURIComponent(
    `services/apexrest/signupotp/verify?email=${email}&otpEnteredSms=${smsOtp}&otpEnteredEmail=${emailOtp}`
  )}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getApplicationDataByContactId = async (contactId) => {
  const path = `salesforce?endpoint=${encodeURIComponent(
    `services/apexrest/investor/retrieve/account?contactid=${contactId}`
  )}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const deleteDocument = async(documentId)=>{
  const delData = { data: {"type":"file", "recordIds":[documentId]} };
  const path = `salesforce?endpoint=/services/apexrest/investor/delete`;
  const res = await axiosInstance.delete(`${path}`, delData);
  return res && res.data ? res.data : null;
}
