import React from 'react';

import KenApplication from '../../Components/KenApplication';
import { ApplicationContextProvider } from '../../Context/applicationContext';

function ApplicationFormNew() {
  return (
    <ApplicationContextProvider>
      <KenApplication />
    </ApplicationContextProvider>
  );
}

export default ApplicationFormNew;
